export const black = '#101010';
export const white = 'white';
export const grey = 'rgba(34, 36, 38, 0.15)';
export const green = '#21ba45';

const themeLight = {
  background: white,
  body: black
};

const themeDark = {
  background: black,
  body: white
};

const theme = mode => (mode === 'dark' ? themeDark : themeLight);

export default theme;
