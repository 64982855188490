import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItemContainer } from './header.styles';
import { useTheme, black, white } from '../../features/dark-mode/ThemeContext';
import { Menu, Container, Responsive, Label } from 'semantic-ui-react';

//components
import Burger from './Burger';
import LogoHolder from './LogoHolder';
import Profile from './Profile';
import CartIcon from '../cart-icon/CartIcon';
import Notifications from './Notifications';
import ThemeSwitcher from '../theme-switcher/ThemeSwitcher';

const Navbar = (props) => {
  const theme = useTheme();

  const handleNavigation = (route) => {
    props.navigation.push(route);
  };

  return (
    <Menu
      fixed='top'
      tabular
      style={{ background: theme?.dark ? black : white }}
    >
      <Container>
        <LogoHolder />
        <Responsive minWidth={800}>
          <Menu.Menu position='right' style={{ marginTop: 15 }}>
            <Menu.Item>
              <MenuItemContainer as={Link} to='/rentals'>
                Rent
              </MenuItemContainer>
            </Menu.Item>
            <Menu.Item>
              <MenuItemContainer as={Link} to='/rentals/search'>
                Search
              </MenuItemContainer>
            </Menu.Item>
            <Menu.Item>
              {props.wishlistCount > 0 && (
                <Label
                  style={{ position: 'absolute', left: 67, bottom: 22 }}
                  color='teal'
                  circular
                  content={props.wishlistCount}
                />
              )}
              <MenuItemContainer as={Link} to='/rentals/wishlist'>
                Wishlist
              </MenuItemContainer>
            </Menu.Item>
            {props.authUser ? (
              <Menu.Item>
                <MenuItemContainer as={Link} to='/store'>
                  My Store
                </MenuItemContainer>
              </Menu.Item>
            ) : (
              <Menu.Item onClick={props.handleOpenModal}>
                <MenuItemContainer>Login</MenuItemContainer>
              </Menu.Item>
            )}
            <Menu.Item>
              <ThemeSwitcher />
            </Menu.Item>
     
          </Menu.Menu>
        </Responsive>
        <Menu.Item>
          <Burger handleMenuOpen={props.handleMenuOpen} />
        </Menu.Item>
        <Menu.Item fitted>
          <CartIcon />
        </Menu.Item>
        <Menu.Item
          style={{ marginTop: 5 }}
          fitted
          onClick={props.setDrawerOpen}
        >
          <Notifications />
        </Menu.Item>
        {props.authUser && (
          <Menu.Item fitted>
            <Responsive minWidth={800}>
              <Profile
                authUser={props.authUser}
                handleSignOut={props.handleSignOut}
                navigate={handleNavigation}
              />
            </Responsive>
          </Menu.Item>
        )}
      </Container>
    </Menu>
  );
};

export default Navbar;
