import styled from 'styled-components';
import { Segment, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const AppWrapper = styled.div`
  background: ${(props) => props.theme.background};
  padding-top: 7em;
  min-height: 100vh;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    color: ${(props) => props.theme.body};
    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
  div {
    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
  label {
    color: ${(props) => props.theme.body} !important;
  }
  transition: all 0.15s linear;
`;

export const SegmentContainer = styled(Segment)`
  background: ${(props) => props.theme.background} !important;
  border-color: ${(props) =>
    props.theme.body === 'white' ? 'white' : ''} !important;
  display: ${({ flex }) => flex && 'flex'};
  flex-direction: ${({ column }) => column && 'column'};
  justify-content: ${({ flexend }) => flexend && 'flex-end'};
  min-height: ${({ min }) => min && '180px'} !important;
  color: ${(props) => props.theme.body} !important;
  div {
    font-size: 15px !important;
    @media screen and (max-width: 800px) {
      font-size: 16px !important;
    }
  }
`;

export const SimpleContainer = styled(Segment)`
  background: ${(props) => props.theme.background} !important;
  border-color: ${(props) =>
    props.theme.body === 'white' ? 'white' : ''} !important;
  min-height: ${({ min }) => min && '180px'} !important;
  color: ${(props) => props.theme.body} !important;
`;

export const Horizontal = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-content: 'center';
  border: ${props => props.border ? 'solid 1px' : null};
  border-bottom: solid 1px;
  border-color: rgba(34, 36, 38, 0.15);
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 10px;
`;

export const Span = styled.span`
  color: ${(props) => props.theme.body} !important;
  @media screen and (max-width: 800px) {
    font-size: 16px !important;
  }
`;

export const Paragraph = styled.div`
  line-height: 2;
  font-size: 15px;
  @media screen and (max-width: 800px) {
    font-size: 18px !important;
  }
`;

export const MessageContainer = styled(Message)`
  width: 80%;
  position: absolute !important;
  z-index: 100 !important;
  @media screen and (min-width: 800px) {
    width: 40% !important;
  }
`;

export const ExternalLinkContainer = styled.span`
  margin-top: 10px;
  a {
    color: blue;
  }
`;
export const LinkContainer = styled(Link)`
  color: ${(props) =>
    props.theme.body === 'white' ? '#00ccff' : 'blue'} !important;
  font-size: 15px;
  @media screen and (max-width: 800px) {
    font-size: 18px !important;
  }
`;
