import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/actions/modalActions';

const Authenticated = (Component) => {

  return function WrappedComponent(props) {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth?.currentUser);
    
    useEffect(() => {
        if (!currentUser) {
          dispatch(openModal('LoginModal'));
        } 
        else if (currentUser && !currentUser.emailVerified) {
          dispatch(
            openModal('ConfirmModal', {
              content:'You need to verify your email first before accessing this part of the app. If you have already verified it, click OK to re-login.',
              size: 'tiny',
              header: 'Attention',
              confirmButtonText: 'OK',
              style: { marginLeft: '20px' },
              handleConfirm: () => dispatch(openModal('LoginModal'))
            })
          );
        } 

    }, [currentUser, dispatch]);
    
    return <Component {...props} />;
  };
};

export { Authenticated };
