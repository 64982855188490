import cuid from 'cuid';
import { db, storage, serverTimestamp, GeoFirestore, GeoPoint } from '../../firebase';
import { asyncActionStart, asyncActionFinish } from './asyncActions';
import { toastr } from 'react-redux-toastr';
import { convertToSearchFields } from '../../utils/component-utils/helpers';

const storageRef = storage.ref();

/**
 * @summary initial action for user to add rental and picture
 * @param {Object} latLng contains lat and lng properties
 * @param  {Object} formData address, category, file?, region
 * image, imageName, instructions, ownerUid, full_day_price, half_day_price, name
 * @returns void
 */
export const uploadRental = (latLng, formData) => async (
  dispatch,
  getState
) => {
  const user = getState().auth.currentUser;
  const rentalDocRef = GeoFirestore.collection('rentals').doc(); 
  const path = `${user.uid}/rental_images`;
  const { file, ...data } = formData;
  try {
    let downloadUrl = null;
    let imageName = null;
    if (typeof file === 'object' && file != null) {
      //uppend the rental id to image so that backend can put the file in correct doc
      imageName = `${rentalDocRef.id}_` + cuid();
      const metadata = { contentType: formData.file.type };
      //upload the file to firebase storage
      const uploadedFile = await storageRef
        .child(`${path}/${imageName}`)
        .put(file, metadata);
      //get url back of the image
      downloadUrl = await uploadedFile.ref.getDownloadURL();
    }
    //add search_fields for easy quering by users in the search page
    const searchFields = convertToSearchFields(data.name, data.full_day_price, data.half_day_price);

    //add a rental to firestore collection with image url
    await rentalDocRef.set({
      ...data,
      displayName: user.displayName,
      ownerImage: user.photoURL,
      image: downloadUrl,
      ownerUid: user.uid,
      search_fields: searchFields,
      date: serverTimestamp(),
      status: 'active',
      coordinates: new GeoPoint(latLng.lat, latLng.lng)
    });
    toastr.success('Success', 'Your rental is uploaded.');
  } catch (err) {
    toastr.error('Error', 'Oops. Could not upload your rental.');
  }
};

/**
 * @summary updates existing rental doc
 * @param {Object} latLng contains lat, lng properties
 * @param  {String} rentalId
 * @param  {Object} formData address, category, file?, region
 * image, imageName, instructions, ownerUid, price, name
 * @returns void
 */
export const updateRental = (latLng, rentalId, formData) => async (
  dispatch,
  getState
) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const rentalDocRef = GeoFirestore.doc(`rentals/${rentalId}`);
  const storageRef = storage.ref();
  const { file, ...data } = formData;
  try {
    let downloadUrl = formData.file;
    let imageName = formData.imageName;
    //check if there is a new file attached, otherwise skip to updating rentals collection
    if (typeof file === 'object' && file != null) {
      const path = `${user.uid}/rental_images`;
      const metadata = { contentType: formData.file.type };
      //uppend the rental id to image so that backend can put the file in correct doc
      imageName = `${rentalDocRef.id}_` + cuid();
      //upload the file to firebase storage
      const uploadedFile = await storageRef
        .child(`${path}/${imageName}`)
        .put(formData.file, metadata);
      //get url back of the image
      downloadUrl = await uploadedFile.ref.getDownloadURL();
    }
    ///add search_fields for easy quering by users in the search page
    const searchFields = convertToSearchFields(data.name, data.full_day_price, data.half_day_price);
    await rentalDocRef.update({
      ...data,
      image: downloadUrl,
      ownerUid: user.uid,
      search_fields: searchFields,
      coordinates: new GeoPoint(latLng.lat, latLng.lng)
    });
    toastr.success('Success! Your rental is updated.');
  } catch (err) {
    console.log(err.message);
    toastr.error('Error', 'Oops. Could not update your rental.');
  }
};

/**
 * sets the status to unavailable on a rental
 * @param  {String} rentalId
 * @param  {Boolean} success
 */
export const deactivateRental = (rentalId) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const rentalDocRef = db.doc(`rentals/${rentalId}`);
  let success = true; 
  try {
    const snap = await rentalDocRef.get();
    const rentalState = snap.data()?.status;
    if (rentalState === 'rented') {
      toastr.info('Info', 'You can not deactive a rented item.');
      return success = false; 
    }
    const status = rentalState === 'active' ? 'inactive' : 'active';
    await rentalDocRef.update({ status });
    toastr.success('Success', `Your rental is ${status}.`);
    return success; 
  } catch (err) {
    toastr.error('Oops', 'Could not deactivate your rental.');
    return success = false;
  }
};

/**
 * @summary deletes owner rental & clears userRentals reducer if owner has not rentals in db
 * @param  {String} rentalId
 * @returns Object success | error
 */
export const deleteRental = (rentalId) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const rentalDocRef = db.doc(`rentals/${rentalId}`);
  try {
    const snap = await rentalDocRef.get();
    if (snap.data()?.status === 'rented') {
      return toastr.error(
        'Oops',
        'You can not delete rented equipment.'
      );
    }
    await rentalDocRef.delete();
    toastr.success('Success! Your rental is deleted.');
    return { success: 'success' };
  } catch (err) {
    console.log(err.message);
    if(err.message === 'Missing or insufficient permissions.'){
        return toastr.info('Info', 'If you just verified your email, please sign out and login again.')
    }
    toastr.error('Error', err.message);
    return { error: 'error' };
  }
};

/**
 * @summary sets main profile image
 * @param  {Object} file
 * @returns void | toast error
 */
export const uploadProfileImage = (file) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  if (!file) return toastr.error('Oops', 'Please drop an image first.');
  const path = `${user.uid}/user_images`;
  const userDocRef = db.doc(`users/${user.uid}`);
  const imageName = `${user.uid}_` + cuid();
  try {
    dispatch(asyncActionStart());
    //delete the old image first
    const snap = await userDocRef.get();
    if (snap.data()?.imageName) {
      await storageRef
        .child(`${user.uid}/user_images/${snap.data().imageName}`)
        .delete();
    }
    //upload the new file to firebase storage
    const metadata = { contentType: file.type };
    //upload the file to firebase storage
    const uploadedFile = await storageRef
      .child(`${path}/${imageName}`)
      .put(file, metadata);
    //get url back of the image
    const downloadURL = await uploadedFile.ref.getDownloadURL();
    //update profile photo in under auth object
    await user.updateProfile({ photoURL: downloadURL });
    //add photo to user doc
    await userDocRef.update({ photoURL: downloadURL });
    dispatch(asyncActionFinish());
    toastr.success('Success', 'Your image is uploaded.');
  } catch (err) {
    dispatch(asyncActionFinish());
    if(err.message === 'Missing or insufficient permissions.'){
        return toastr.info('Info', 'If you just verified your email, please sign out and login again.')
    }
    toastr.error('Oops', 'Could not upload a profile image.');
  }
};

/**
 * @summary updates user profile and rentals belonging to the user
 * @param  {Object} user has displayName, dob props
 * @return Promise
 */
export const updateProfile = (user) => async (dispatch, getState) => {
  const currentUser = getState().auth.currentUser;
  if (!currentUser) return;
  const batch = db.batch();
  const userDocRef = db.doc(`users/${currentUser.uid}`);
  const privateDocRef = userDocRef.collection('_private').doc(currentUser.uid);
  const rentalsColRef = db
    .collection('rentals')
    .where('ownerUid', '==', currentUser.uid);

  if (!user.dob) {
    Object.assign(user, { dob: null });
  }

  try {
    dispatch(asyncActionStart());
    //fetch the rentals collection snapshop
    const query = await rentalsColRef.get();
    //iterate through query snapshot and update rentals that match the user
    if (!query.empty) {
      query.forEach((doc) => {
        const rentalsToUpdate = db.doc(`rentals/${doc.id}`);
        batch.update(rentalsToUpdate, { displayName: user.displayName });
      });
    }
    //decide if user wants to keep the phone and email as private
    const { phone, email, _private, ...trimmedUser } = user;
    if (_private === 'yes') {
      batch.update(privateDocRef, { email, phone });
      batch.update(userDocRef, {
        ...trimmedUser,
        email: null,
        phone: null,
        _private,
      });
    } else {
      batch.update(userDocRef, { ...user });
    }
    await batch.commit();
    //update profile in auth
    await currentUser.updateProfile(user);
    dispatch(asyncActionFinish());
    toastr.success('Success', 'Your profile is updated.');
  } catch (err) {
    dispatch(asyncActionFinish());
    if(err.message === 'Missing or insufficient permissions.'){
        return toastr.info('Info', 'If you just verified your email, please sign out and login again.')
    }
    toastr.error('Oops', 'Could not update your profile.');
  }
};

