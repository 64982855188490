import React, { useState } from 'react';
import { Container, Button } from 'semantic-ui-react';
import { MessageContainer } from '../../global.styles';
import { Helmet } from 'react-helmet';
import { useAddToHomeScreen } from '../../hooks/useAddToHomeScreen';
import CollectionContainer from '../../components/collection-container/CollectionContainer';
import Footer from '../../components/footer/Footer';

const sections = [
  {
    id: 1,
    name: 'Personal',
    category: 'personal',
    image: '/images/personal.jpg',
  },
  {
    id: 2,
    name: 'Industrial',
    category: 'industrial',
    image: '/images/industrial.jpg',
  },
];

const HomePage = () => {
  const [prompt, init] = useAddToHomeScreen();
  const [isVisible, setVisible] = useState(true);

  return (
    <>
      <Helmet>
        <title>SubRent - Rent equipment</title>
        <meta
          name='description'
          content='Subrent is online marketplace to rent industrial equipment'
        />
      </Helmet>
      <Container>
        {prompt && isVisible && (
          <MessageContainer positive onDismiss={() => setVisible(false)}>
            <Button positive content='Install SubRent' onClick={init} />
          </MessageContainer>
        )}
        <CollectionContainer
          bold
          centered
          exploreButton
          rentals={sections}
          columns={3}
          path='/rentals/categories'
          home
        />
      </Container>

      <Footer />
    </>
  );
};

export default HomePage;
