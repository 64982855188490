import React, { useEffect } from 'react';
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import { chatWithAgent } from '../../firebase/functions';
import botAvatar from '../../assets/chatbot.png';
import 'react-chat-widget/lib/styles.css';
import './widget.custom-styles.css';

const ChatWidget = () => {
  useEffect(() => {
    addResponseMessage('Hi, my name is Bob. How can I help you?');
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    try {
      toggleMsgLoader();
      const { response } = await chatWithAgent(newMessage);
      addResponseMessage(response);
      toggleMsgLoader();
    } catch (err) {
      toggleMsgLoader();
      addResponseMessage(
        'Oops. Bob is on a break. Please contact us at support@subrent.app'
      );
    }
  };

  return (
    <Widget
      profileAvatar={botAvatar}
      handleNewUserMessage={handleNewUserMessage}
      title='Chat with our agent'
      subtitle={null}
    />
  );
};

export default ChatWidget;
