import React from 'react';
import { Menu, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../features/dark-mode/ThemeContext';
import BlackLogo from '../../assets/logo.png';
import WhiteLogo from '../../assets/white_logo.png'; 

const LogoHolder = () => {

  const theme = useTheme(); 
  const Logo = theme?.dark ? WhiteLogo : BlackLogo;
  return (
    <Menu.Item position='left' as={Link} to='/' style={{ padding: 10 }}>
        <Image size='small' src={Logo} alt='Company logo' style={{ objectFit: 'cover' }} />
    </Menu.Item>
  );
};

export default LogoHolder;
