import styled from 'styled-components';
import { ReactComponent as ShoppingIconSVG } from '../../assets/shopping-bag.svg';

export const CartContainer = styled.div`
  width: 35px;
  height: 43px;
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CountContainer = styled.span`
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  bottom: 9px;
  color: ${props => props.theme.body};
`;

export const ShoppingIcon = styled(ShoppingIconSVG)`
  width: 35px;
  height: 35px;
  stroke: #e5e5e5;
  stroke-width: 5px;
`;
