import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { useFormContext, Controller } from 'react-hook-form';

const TextArea = ({ name, rules, errMsg, label, initialValue, ...rest }) => {
  
  const { control, errors } = useFormContext();

  return (
    <Form.Field>
      {label && <label> {label} </label>}
      <Controller
        as={<Form.TextArea {...rest} />}
        defaultValue={initialValue ? initialValue : ''}
        name={name}
        control={control}
        rules={rules}
        mode='onChange'
      />
      {errors[name] && (
        <Label style={{ marginTop: 0, zIndex: 0 }} pointing color='red'>
          {errMsg}
        </Label>
      )}
    </Form.Field>
  );
}

export default TextArea;
