import React from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';

const ReactDatePicker = (props) => {
  return (
    <Form.Field>
      <DatePicker
        isClearable
        dateFormat='MMM d, yyyy'
        onChangeRaw={(e) => e.preventDefault()}
        {...props}
      />
    </Form.Field>
  );
};

export default ReactDatePicker;
