import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import { useFormContext } from 'react-hook-form';

  
const ResetButton = ({ defaultValues = {}, handleFormChange, ...rest }) => {
  
  const { reset } = useFormContext();

  const handleReset = () => {
    handleFormChange();
    reset(defaultValues);
  };
  
  return <SemanticButton onClick={handleReset} {...rest} />;
};

export default ResetButton;
