import React from 'react';
import { Button } from 'semantic-ui-react';
import { useTheme } from '../../features/dark-mode/ThemeContext';

const ThemeSwitcher = (props) => {
  const theme = useTheme();
  return <Button circular color='teal' icon={theme?.dark ? 'moon' : 'sun'} style={props.style} onClick={() => theme.toggle()} />;
};

export default ThemeSwitcher;

