import { modalTypes } from '../types';

export const openModal = (modalType, modalProps) => ({
    type: modalTypes.MODAL_OPEN,
    payload: {
      modalType,
      modalProps, 
    }
});

export const closeModal = () => ({
  type: modalTypes.MODAL_CLOSE
});
