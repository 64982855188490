import { msgActionTypes } from '../types';
import { db, serverTimestamp, fieldValue } from '../../firebase';
import { asyncActionStart, asyncActionFinish } from './asyncActions';
import { toastr } from 'react-redux-toastr';

/**
 * sends a message to either customer or owner based on current user
 * @param  {Object} data data object must have text, transactionId, recipientId properties
 */
export const sendChatMessage = (data) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const chatSubColRef = db.collection(
    `transactions/${data.transactionId}/messages`
  );
  try {
    await chatSubColRef.add({
      ...data,
      author: user.displayName,
      authorUid: user.uid,
      image: user.photoURL,
      date: serverTimestamp(),
    });
    toastr.success('Success', 'Your message is sent');
  } catch (err) {
    return toastr.error('Oops', 'Could not send your message.');
  }
};
/**
 * clears the message map in users doc and dispathes action to clear
 * userMessages in the reducer
 * @param transactionId
 */
export const clearMessageMap = (transactionId) => async (
  dispatch,
  getState
) => {
  const user = getState().auth.currentUser;
  if (!user || !transactionId) return;
  const userDocRef = db.doc(`users/${user.uid}`);
  try {
    const snap = await userDocRef.get();
    const messages = snap.data().messages;
    if (messages && messages[transactionId]) {
      await userDocRef.update({
        [`messages.${transactionId}`]: fieldValue.delete(),
      });
      dispatch({
        type: msgActionTypes.CLEAR_USER_MESSAGES,
        payload: { transactionId },
      });
    }
  } catch (err) {
    toastr.error('Oops', 'Could not clear notification.');
  }
};

/**
 * @summary sends messages between rental owner and customer
 * @param data has recipientId, subject, text
 * @returns {Toast | Error} success | error
 */
export const contactOwner = (data) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  //note that threadId is also rental owner id that inquirer sends message to
  const { threadId, text, subject } = data;
  if (!user || !threadId) return;
  const batch = db.batch();
  const newThread = db.collection('conversations').doc();
  const newMessage = db
    .doc(`conversations/${newThread.id}`)
    .collection('messages')
    .doc();
  const existingThreadRef = db.doc(`conversations/${threadId}`);
  const existingThreadMsgRef = db
    .doc(`conversations/${threadId}`)
    .collection('messages')
    .doc();

  const { uid, photoURL, displayName } = user;
  let destId = threadId;
  let senderId = uid;
  try {
    dispatch(asyncActionStart());
    if (subject) {
      batch.set(newThread, {
        subject,
        date: serverTimestamp(),
        author: displayName,
        lastUpdatedId: uid,
        participants: [uid, threadId],
      });
      batch.set(newMessage, {
        senderId,
        text,
        date: serverTimestamp(),
        author: displayName,
        authorUid: user.uid,
        image: photoURL,
        recipientId: destId,
      });
      await batch.commit();
      dispatch(asyncActionFinish());
      toastr.success('Success', 'You can view your messages in My Store.');
    } else {
      await existingThreadRef.update({ lastUpdatedId: uid });
      const snap = await existingThreadRef.get();
      const { lastUpdatedId, participants } = snap.data();

      //identify who needs to get a notification
      //based on who updated thread last
      if (lastUpdatedId && participants) {
        //find id of the participant who did not updated the thread
        destId = participants.find((id) => id !== lastUpdatedId);
        senderId = participants.find((id) => id === lastUpdatedId);
      }
      batch.set(existingThreadMsgRef, {
        text,
        senderId,
        date: serverTimestamp(),
        author: displayName,
        authorUid: user.uid,
        image: photoURL,
        recipientId: destId,
      });
      await batch.commit();
      dispatch(asyncActionFinish());
      toastr.success('Success', 'Your message is sent.');
    }
  } catch (err) {
    dispatch(asyncActionFinish());
    throw new Error(err.message);
  }
};

export const getNotifications = () => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user?.uid || !user?.emailVerified) return;
  const userDocRef = db.doc(`users/${user.uid}`);
  try {
    const snap = await userDocRef.get();
    const transactCount = snap.data().transactions || null;
    dispatch({
      type: msgActionTypes.GET_TRANSACT_COUNT,
      payload: { transactCount },
    });
    const messages = snap.data()?.messages;
    if (messages) {
      const msgMap = Object.keys(messages).map((k) => ({
        key: k,
        count: messages[k].count,
        type: messages[k].type || null,
      }));
      dispatch({
        type: msgActionTypes.GET_USER_MESSAGES,
        payload: { msgMap },
      });
    }
  } catch (err) {
    toastr.error('Oops', 'Could not get notifications.');
  }
};

export const clearMessage = () => ({
  type: msgActionTypes.CLEAR_MESSAGE,
});

export const clearBalance = () => ({
  type: msgActionTypes.CLEAR_BALANCE,
});
