import styled from 'styled-components';

export const MobileItemHolder = styled.h3`
  color: white !important; 
  margin: 8px 5px !important;
  padding: 5px !important;
`

export const FooterContainer = styled.div`
  background: ${({ theme }) =>
    (theme.background === 'white' || 'black') && '#3E413E'};
  border-top: 2px solid gray;
  position: fixed;
  left: 0;
  bottom: 0; 
  max-height: 40%;
  width: 100%;
  margin-top: 10% !important;
  color: whitesmoke;
  p {
    color: whitesmoke !important;
  }
  a {
    color: whitesmoke !important;
    font-size: 16px;
  }

  @media screen and (max-width: 800px) {
    position: static;
    margin-top: 10% !important;
  }
`;


