import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

let composedEnhancer;
if (process.env.NODE_ENV !== 'production') {
  composedEnhancer = composeWithDevTools(applyMiddleware(thunk));
} else {
  composedEnhancer = applyMiddleware(thunk);
} 
export const store = createStore(rootReducer, composedEnhancer);
export const persistor = persistStore(store);

