import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { closeModal } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { contactOwner } from '../redux/actions';
import { notifyRenter } from '../redux/actions';
import { useTheme, black, white } from '../features/dark-mode/ThemeContext';
import Form from '../components/chat-elements/ChatForm';

const ContactModal = (props) => {
    
  const theme = useTheme();
  const dispatch = useDispatch();
  const [err, setErr] = useState(null);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = async (data) => {
    try {
      if(props.action === 'contactOwner') {
         await dispatch(contactOwner(Object.assign(data, { threadId: props.id}))); 
      }      
      if(props.action === 'notifyRenter'){
         await dispatch(notifyRenter({...data, ...props.params }))  
      }
      handleCloseModal();
    } catch (err) {
      setErr(err.message);
    }
  };

  const background = theme.dark ? black : white;
  const color = theme.dark ? white : black;

  return (
    <Modal size='small' open={true} onClose={handleCloseModal}>
      <Modal.Header style={{ background, color }}>{props.header}</Modal.Header>
      <Modal.Content  style={{ background }}>
        <Modal.Description>
          <Form
            positive
            textArea
            placeholder='Your message'
            handleSubmit={handleSubmit}
            loading
            err={err}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ContactModal;
