import { db, serverTimestamp } from '../../firebase';
import { toastr } from 'react-redux-toastr';

/**
 * @summary averages rating for each transaction
 * @param rentalId
 * @param rating
 * @param transactionId
 * @returns Promise<void> | ToastError
 */
export const rateTransaction = (data) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const { rentalId, transactionId, rating } = data;
  const name = user.displayName;
  const rentalDocRef = db.doc(`rentals/${rentalId}`);
  const transDocRef = db.doc(`transactions/${transactionId}`);
  const ratingDocRef = db
    .doc(`rentals/${rentalId}`)
    .collection('ratings')
    .doc();
  try {
    //check if rator is not a transaction owner
    const transSnap = await transDocRef.get();
    const { ownerUid } = transSnap.data();
    if (ownerUid === user.uid) {
      return toastr.error('Oops', 'As owner you cannot rate this transaction');
    }
    await db.runTransaction(async (t) => {
      //check if customer rated already
      const rentalDocSnap = await t.get(rentalDocRef);
      if(!rentalDocSnap.exists){
          return toastr.info('Info', 'The rental no longer exists and can not be rated.');
      } 
      //extract numRatins and avgRating
      const { numRatings, avgRating } = rentalDocSnap.data();

      // Compute new number of ratings and average it out
      const newNumRatings = !numRatings ? 1 : numRatings + 1;
      const oldRatingTotal = !avgRating ? 0 : avgRating * numRatings;
      const newAvgRating = (oldRatingTotal + rating) / newNumRatings;

      // update rental doc
      t.update(rentalDocRef, {
        numRatings: newNumRatings,
        avgRating: newAvgRating,
      });
      //create a new rating
      t.set(ratingDocRef, { name, rating, newAvgRating });
      toastr.success('Success', 'Your rating is submitted.');
    });
  } catch (err) {
    console.log(err.message);
    toastr.error('Oops', 'Something went wrong with rating.');
  }
};
/**
 * @summary allows owner to close transaction and posts message
 * @param {String} transId id of the transaction
 * @param {String} rentalId id of the rental
 * @param {String} customerUid id of the customer
 * @returns {Toastr} toastr success
 */
export const closeTransaction = (transId, rentalId, customerUid) => async (
  dispatch,
  getState
) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const author = user.displayName;
  const transactionDocRef = db.doc(`transactions/${transId}`);
  const rentalDocRef = db.doc(`rentals/${rentalId}`);
  const messagesColRef = db
    .doc(`transactions/${transId}`)
    .collection('messages');
  try {
    const snap = await transactionDocRef.get();
    if (snap.data().status === 'closed') {
      return toastr.info('Info', 'Your transaction is already closed.');
    }
    await rentalDocRef.update({ status: 'free' });
    await transactionDocRef.update({ status: 'closed' });
    await messagesColRef.add({
      author,
      recipientId: customerUid,
      text: 'This transaction is closed.',
      date: serverTimestamp(),
      image: user.photoURL,
    });
    return toastr.success('Success', 'Your transaction is closed.');
  } catch (err) {
    return toastr.error('Oops', 'Could not close your transaction.');
  }
};

