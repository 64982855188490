import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/actions/modalActions';


export const BaseAuthenticated = (Component) => {

    return function WrappedComponent(props) {
  
      const dispatch = useDispatch();
      const currentUser = useSelector((state) => state.auth?.currentUser);
      
      useEffect(() => {
          if (!currentUser) dispatch(openModal('LoginModal'));
      }, [currentUser, dispatch]);
      
      return <Component {...props} />;
    };
  };

