import React, { useRef } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DrawerHolder, Banner, CustomSegment, Span } from './drawer.styles';

const Drawer = ({ open, setDrawerOpen, authUser }) => {
  const drawerRef = useRef();
  const history = useHistory();
  const transCnt = useSelector((state) => state.messages.transactCnt);
  const msgCnt = useSelector((state) => state.messages.userMsgCnt);
  const messages = useSelector((state) => state.messages.userMessages);
  useClickOutside(drawerRef, setDrawerOpen, open);

  function handleClick(path) {
    return function () {
      history.push(path);
      setDrawerOpen(false);
    };
  }

  function renderMsgLinks() {
    return messages?.map((message) => {
      if (message.type === 'userMessage') {
        return (
          <p style={{ marginTop: 5}} key={message.key}>
            <Span onClick={handleClick(`/store/messages/${message.key}`)}>
              You have have{' '}
              {message.count > 1 ? `${message.count} messages` : '1 message'}
            </Span>
          </p>
        );
      }
      return (
        <p style={{ marginTop: 5}} key={message.key}>
          <Span onClick={handleClick(`/store/transactions/${message.key}`)}>
            You have have{' '}
            {message.count > 1 ? `${message.count} messages` : '1 message'}
          </Span>
        </p>
      );
    });
  }

  function renderTransLinks() {
    if (transCnt > 0) {
      return (
        <Span onClick={handleClick('/store/owner-transactions')}>
          You have{' '}
          {transCnt === 1
            ? '1 new transaction'
            : `${transCnt} new transactions`}
        </Span>
      );
    }
  }

  const total = msgCnt + transCnt;

  return (
    <DrawerHolder ref={drawerRef} open={open}>
      <Banner>
        <h3> Notifications </h3>
      </Banner>
      {!total ? (
        <CustomSegment placeholder>
          <Header as='h4' icon>
            <Icon name='binoculars' />
            All clear now
          </Header>
        </CustomSegment>
      ) : (
        <>
          {(authUser || total) && (
            <CustomSegment>
              {renderTransLinks()}
              {renderMsgLinks()}
            </CustomSegment>
          )}
        </>
      )}
    </DrawerHolder>
  );
};

export default Drawer;
