import React from 'react';
import { MobileBurger } from './header.styles';

const Burger = ({ handleMenuOpen }) => {
  return (
    <MobileBurger onClick={handleMenuOpen}>
      <span />
      <span />
      <span />
    </MobileBurger>
  );
};

export default Burger;
