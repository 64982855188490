import React  from 'react';
import { Label, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const Notifications = () => {
    
  const transCnt  = useSelector((state) => state.messages.transactCnt);
  const msgCnt  = useSelector((state) => state.messages.userMsgCnt);

  return (
    <>
      {(transCnt > 0 || msgCnt > 0) && (
        <Label
          style={{
            position: 'absolute',
            left: 3,
            bottom: 26,
            zIndex: 1,
          }}
          color='red'
          circular
          content={transCnt + msgCnt}
        />
      )}
      <Icon color='grey' name='bell' size='big' />
    </>
  );
};

export default Notifications;
