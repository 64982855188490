import React from 'react';
import { Rating, Header } from 'semantic-ui-react';

const RatingComponent = ({
  handleRate,
  avgRating,
  text,
  ...rest
}) => {
  return (
    <>
      {text && (
        <Header  sub>
          <p> {text}</p>
        </Header>
      )}
      <Rating
        icon='star'
        defaultRating={avgRating}
        maxRating={5}
        onRate={handleRate}
        {...rest}
      />
    </>
  );
};

export default RatingComponent;
