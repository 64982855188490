import * as geofirestore from 'geofirestore'; 
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(config);


//firebase services
export const analytics = firebase.analytics(); 
export const persistence = firebase;
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const storage = firebase.storage();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firestoreDate = firebase.firestore.Timestamp;
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fieldValue = firebase.firestore.FieldValue;
export const getCurrentUser = () => {
  return new Promise((res, rej) => {
    const unsubscribe = auth.onAuthStateChanged((curUser) => {
      unsubscribe();
      res(curUser);
    }, rej);
  });
};

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      console.log('multiple tabs open which can not support offline persistence');
    } else if (err.code === 'unimplemented') {
      console.log('browser does not support online persistenace');
    }
  });

//create firestore instance
export const db = firebase.firestore();

// Create a GeoFirestore reference
export const GeoFirestore = geofirestore.initializeApp(db);


//Firestore GeoPoint Class 
export const GeoPoint = firebase.firestore.GeoPoint;

// cloud messaging
let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_VAPID_KEY);
}

export { messaging };
