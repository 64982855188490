import React from 'react';
import { Button, Label } from 'semantic-ui-react';
import {
  CartItemContainer,
  CartItemImage,
  ItemColumn,
  CartButtonContainer,
} from './cart-item.styles';

const CartItem = ({
  redirectToRentPage,
  clearItemFromCart,
  item: { id, image, half_day_price, full_day_price },
}) => {
  return (
    <CartItemContainer>
      <ItemColumn>
        <CartItemImage src={image || '/images/fallback.png'} />
      </ItemColumn>
      <ItemColumn>
        <Label horizontal color='teal'>
          Full day: $ {`${full_day_price}`}{' '}
        </Label>
        <Label horizontal color='teal'>
          Half day: $ {`${half_day_price}`}{' '}
        </Label>
      </ItemColumn>
      <CartButtonContainer>
        <Button
          circular
          positive
          icon='step backward'
          onClick={(e, data) => redirectToRentPage(e, data, id)}
        />
        <Button circular color='red' icon='trash' onClick={clearItemFromCart} />
      </CartButtonContainer>
    </CartItemContainer>
  );
};

export default CartItem;
