import React from 'react';
import { Grid, Card, Label, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom'; 
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTheme, black, white } from '../../features/dark-mode/ThemeContext';

const Thread = ({ author, subject, date, path, id }) => {

  const theme = useTheme(); 
  const history = useHistory(); 
  const currentUser = useSelector(state => state.auth.currentUser?.displayName);
  const handleRedirect = () => {
     history.push(`${path}/${id}`)
  };

  const background = theme.dark ? black : white;
  const color = theme.dark ? white : black;
     
  return (
    <Grid.Column>
      <Card fluid style={{ height: 200 }}>
        <Card.Content style={{ background }}>
          <Label icon='mail' color='green' attached='top right' />
          <Card.Header style={{ marginTop: 20, color}}>{author === currentUser ? 'Me': author}</Card.Header>
          <Card.Meta style={{color}}>
            {format(date.toDate(), 'MMM d yyyy')}
          </Card.Meta>
          <Card.Description style={{ color}}>{subject}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button floated='right' onClick={handleRedirect}>
            View all
          </Button>
        </Card.Content>
      </Card>
    </Grid.Column>
  );
};

export default Thread;
