import { modalTypes } from '../types';

const modalReducer = (state = null, action) => {
  switch (action.type) {
    case modalTypes.MODAL_OPEN: {
      const { modalType, modalProps } = action.payload;
      return { 
        modalType, 
        modalProps, 
      };
    }
    case modalTypes.MODAL_CLOSE:
      return null;
    default:
      return state;
  }
}; 

export default modalReducer;
