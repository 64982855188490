import React from 'react';
import { ChatFormContainer } from './chat-elements.styles';
import Form, { TextInput, TextArea, Button } from '../form/Form';
import { useSelector } from 'react-redux';
import ErrorMessage from '../error-message/ErrorMessage';

const ChatForm = ({
  handleSubmit,
  placeholder,
  positive,
  err,
  withSubject,
  rows,
}) => {
  const loading = useSelector((state) => state.loading);

  return (
    <ChatFormContainer clearing>
      <Form onSubmit={handleSubmit}>
        {withSubject && (
          <TextInput
            name='subject'
            placeholder='Subject'
            autoComplete='off'
            errMsg='Subject is required'
            rules={{ required: true }}
            maxLength={18}
          />
        )}
        <TextArea
          name='text'
          placeholder={placeholder}
          autoComplete='off'
          errMsg='Message can not be empty'
          rules={{ required: true }}
          rows={rows}
        />
        <ErrorMessage centered err={err} />
        <Button
          size='large'
          loading={loading}
          positive={positive}
          floated='right'
        >
          Send
        </Button>
      </Form>
    </ChatFormContainer>
  );
};

export default ChatForm;
