import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Label } from 'semantic-ui-react';
import {
  normalizePhone,
  normalizeRouting,
  normalizePostalCode,
  normalizeBankAccount,
} from '../../utils/component-utils/helpers';

const TextInput = ({
  name,
  errMsg,
  rules,
  initialValue,
  normalize,
  handleRemoveErr,
  ...rest
}) => {
  const { errors, control } = useFormContext();

  const handleChange = ([, props]) => {
    if (normalize) {
      switch (normalize) {
        case 'phone':
          return normalizePhone(props.value);
        case 'routing':
          return normalizeRouting(props.value);
        case 'postal':
          return normalizePostalCode(props.value);
        case 'bank-account':
          return normalizeBankAccount(props.value);
        default:
          break;
      }
    }
  };

  return (
    <Form.Field>
      <Controller
        as={<Form.Input onClick={handleRemoveErr} {...rest} />}
        defaultValue={initialValue ? initialValue : ''}
        name={name}
        rules={rules}
        mode='onChange'
        control={control}
        onChange={normalize ? handleChange : ''}
      />
      {errors[name] && (
        <Label style={{ marginTop: 0, zIndex: 0 }} pointing color='red'>
          {errMsg}
        </Label>
      )}
    </Form.Field>
  );
}

export default TextInput;
