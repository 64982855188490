import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Form as SemanticForm } from 'semantic-ui-react';

const Form = ({ children, onSubmit, ...rest }) => {
    
  const methods = useForm();
  const reset = methods.reset;

  return (
    <FormContext {...methods}>
      <SemanticForm
        onSubmit={methods.handleSubmit((data) => onSubmit(data, reset))}
        {...rest}
      >
        {children}
      </SemanticForm>
    </FormContext>
  );
};

export { default as Button } from './Button';
export { default as ResetButton } from './ResetButton';
export { default as TextInput } from './TextInput';
export { default as TextArea } from './TextArea';
export { default as Select } from './Select';
export { default as RadioInput } from './RadioInput';
export { default as PlacesInput } from './PlacesInput';
export { default as DateInput } from './DateInput';
export { default as CheckInput } from './CheckBox';



export default Form;
