import { closeModal } from './modalActions';
import { authTypes } from '../types';
import { openModal } from './modalActions';
import { toastr } from 'react-redux-toastr';
import { getNotifications } from './messageActions';
import {
  auth,
  getCurrentUser,
  db,
  googleProvider,
  serverTimestamp,
} from '../../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from './asyncActions';

const actionCodeSettings = {
  url: process.env.REACT_APP_DOMAIN,
};
/**
 * sends verification email to the use signed but whose email is not verified
 * @returns void 
 */
export const resendEmailVerification = () => async (dispatch, getState) => {
  const user = getState().auth.currentUser; 
  if (!user) return;
  try {
    if(user && user.emailVerified) return toastr.info('Info', 'Your email is already verified.')
    dispatch(asyncActionStart());
    if (user && !user.emailVerified) {
      await user.sendEmailVerification(actionCodeSettings);
      dispatch(asyncActionFinish());
      toastr.success('Success', 'We resent you the verification email.');
    }
    dispatch(asyncActionFinish());
  } catch (err) {
    dispatch(asyncActionFinish());
    toastr.error('Oops', 'Could not send a verification email.');
  }
}; 

export const sendPasswordReset = (email) => async (dispatch) => {
  try {
    dispatch(asyncActionStart());
    await auth.sendPasswordResetEmail(email);
    dispatch(asyncActionFinish());
    dispatch(closeModal('LoginModal'));
    toastr.success(
      'Sucess',
      'Password reset email is sent. Please check your email'
    );
  } catch (err) {
    dispatch(asyncActionFinish());
    throw err;
  }
};

/**
 * @summary logins user
 * @param  {Object} user contains email & password props
 * @returns void
 */
export const loginUser = (user) => async (dispatch) => {
  try {
    dispatch(asyncActionStart());
    const data = await auth.signInWithEmailAndPassword(
      user.email,
      user.password
    );
    const fetchedUser = data.user;
    dispatch({
      type: authTypes.SIGN_IN_SUCCESS,
      payload: { fetchedUser },
    });
    dispatch(getNotifications());
    dispatch(asyncActionFinish());
    dispatch(closeModal());
    toastr.success(
      'Success',
      `We signed you in as ${fetchedUser.displayName}.`
    );
  } catch (err) {
    dispatch(asyncActionError());
    dispatch({ type: authTypes.SIGN_IN_FAILURE });
    let errMessage = '';
    if (err.code === 'auth/user-not-found') {
      errMessage = 'This user is not registered. Please sign up first';
    }
    if (err.code === 'auth/wrong-password') {
      errMessage = 'Your password or email is incorrect';
    }
    if (err.code === 'auth/too-many-requests') {
      errMessage = 'Too many attempts. Please try to login later';
    }
    throw new Error(errMessage);
  }
};
/**
 * @summary registers user
 * @param  {Object} user contains userName and email props
 * @param void
 */
export const registerUser = (user) => async (dispatch) => {
  try {
    dispatch(asyncActionStart());
    const fetchedUser = await auth.createUserWithEmailAndPassword(
      user.email,
      user.password
    );
    await fetchedUser.user.updateProfile({ displayName: user.userName });
    await db
      .doc(`users/${fetchedUser.user.uid}`)
      .set({ displayName: user.userName, createdAt: serverTimestamp() });
    await db
      .doc(`users/${fetchedUser.user.uid}/_private/${fetchedUser.user.uid}`)
      .set({ email: user.email });
    await fetchedUser.user.sendEmailVerification(actionCodeSettings);
    dispatch(asyncActionFinish());
    dispatch(closeModal());
    toastr.success(
      'Success',
      'Please check your inbox to verify your email. If you don’t have it within 5 minutes, login and go to Settings to resend it.'
    );
  } catch (err) {
    dispatch(asyncActionError());
    dispatch({ type: authTypes.SIGN_IN_FAILURE });
    throw new Error(err.message);
  }
};
/**
 * @summary checks if uses session is valid
 * @returns void
 */
export const checkUserSession = () => async (dispatch) => {
  try {
    dispatch({ type: authTypes.SET_AUTH_LOADING });
    const fetchedUser = await getCurrentUser();
    dispatch({
      type: authTypes.SET_CURRENT_USER,
      payload: { fetchedUser },
    });
  } catch (err) {
    dispatch({ type: authTypes.SIGN_IN_FAILURE });
  }
};

/**
 * @summary updates user password
 * @param  {Object} creds contains password1 & password2 props
 * @returns Boolean | Toastr.error
 */
export const updatePassword = (creds) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user || !user.emailVerified) return;
  let success = true;
  const { password1, password2 } = creds;
  if(!password1 || !password2) {
    return toastr.info('Info', 'Please enter passwords in the required fields.');
  }
  if (password1 !== password2)
    return toastr.info('Info', 'Passwords did not match.');
  try {
    await user.updatePassword(password2);
    toastr.success('Success', 'Your password is updated.');
    return success;
  } catch (err) {
    let message = 'Could not update your password';
    success = false;
    if (
      err.message ===
      'This operation is sensitive and requires recent authentication. Log in again before retrying this request.'
    ) {
      message = 'Please login again. This action is sensative.';
      dispatch(openModal('LoginModal'));
    }
    toastr.error('Oops', message);
    return success;
  }
};

/**
 * @summary function logs user in via Google_Auth
 */
export const socialLogin = () => async (dispatch) => {
  try {
    dispatch(closeModal());
    const result = await auth.signInWithPopup(googleProvider);
    const { user, additionalUserInfo } = result;
    const privDocRef = db.doc(`users/${user.uid}/_private/${user.uid}`);
    const userDocRef = db.doc(`users/${user.uid}`);
    await db.runTransaction(async (t) => {
      if (additionalUserInfo.isNewUser) {
        const snap = await t.get(privDocRef);
        t.set(userDocRef, {
          displayName: user.displayName,
          authType: 'google',
          createdAt: serverTimestamp(),
        });
        if (snap.exists) {
          t.update(privDocRef, { email: user.email });
        } else {
          t.set(privDocRef, { email: user.email });
        }
      }
    });
    dispatch({
      type: authTypes.SIGN_IN_SUCCESS,
      payload: { fetchedUser: user },
    });
    toastr.success('Success', `We signed you in as ${user.displayName}`);
  } catch (err) {
    toastr.error('Oops', 'Could not sign you via Google.');
  }
};

/**
 * @summary signs out user
 * @returns void
 */
export const signOut = () => async (dispatch) => {
  await auth.signOut();
  dispatch({ type: authTypes.SIGN_OUT });
  toastr.success('Success', 'We signed you out.');
};
