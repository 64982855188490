import React, { Component } from 'react';
import { NetworkMessageContainer } from './styles';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

export function networkDetector(ComposedComponent) {
    
  class NetworkDetector extends Component {
    
    state = {
      isDisconnected: false,
    };

    componentDidMount() {
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      if (this.props.currentUser?.uid) {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
          const webPing = setInterval(() => {
            fetch('//google.com', {
              mode: 'no-cors',
            })
              .then(() => {
                this.setState({ isDisconnected: false, visible: true }, () => {
                  return clearInterval(webPing);
                });
              })
              .catch(() => {
                this.setState({ isDisconnected: true });  
              })
          }, 2000);
          return;
        }

        return this.setState({ isDisconnected: true });
      }
    };

    render() {
      const { isDisconnected } = this.state;
      return (
        <>
          {isDisconnected && (
            <NetworkMessageContainer>
                <Message warning>
                  <h3> Oops ! No internet connection detected.</h3>{' '}
                  <p> Please check your network for connectivity. </p>
                </Message>
            </NetworkMessageContainer>
          )}
          <ComposedComponent {...this.props} />
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return { currentUser: state.auth.currentUser };
  }

  return connect(mapStateToProps)(NetworkDetector);
}
