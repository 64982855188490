import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const ChatContainer = styled.div`
  border-style: solid;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 5px 5px;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  padding: 15px;
  overflow-y: scroll;
  max-height: 225px; 
`;

export const ChatFormContainer = styled(Segment)`
  margin-top: 20px !important;
  background: ${(props) => props.theme.background} !important;
  color: ${(props) => props.theme.color} !important;
  border: 1px solid #ccc !important;
`;

