import { msgActionTypes } from '../types';

const initialState = {
  stripeBalance: null,
  fundsLoading: false,
  userMessages: [],
  transactCnt: null,
  userMsgCnt: null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case msgActionTypes.SET_FUNDS_LOAD:
      return {
        ...state,
        fundsLoading: true,
      };
    case msgActionTypes.END_FUNDS_LOAD: 
     return {
         ...state, 
        fundsLoading: false 
     }
    case msgActionTypes.GET_TRANSACT_COUNT:
      return {
        ...state,
        transactCnt: action.payload.transactCount,
      };
    case msgActionTypes.CLEAR_TRANSACT_COUNT:
      return {
        ...state,
        transactCnt: null,
      };
    case msgActionTypes.GET_USER_MESSAGES:
      return {
        ...state,
        userMessages: action.payload.msgMap,
        userMsgCnt: action.payload.msgMap.reduce(
          (acc, tail) => acc + tail.count,
          0
        ),
      };
    case msgActionTypes.CLEAR_USER_MESSAGES: {
      const existingMsg = state.userMessages.find(
        (msg) => msg.key === action.payload.transactionId
      );
      return {
        ...state,
        userMessages: state.userMessages.filter(
          (msg) => msg.key !== action.payload.transactionId
        ),
        userMsgCnt: state.userMsgCnt - (existingMsg?.count || 0),
      };
    }
    case msgActionTypes.GET_BALANCE:
      return {
        ...state,
        stripeBalance: (action.payload.balance / 100).toFixed(2),
      };
    case msgActionTypes.CLEAR_BALANCE:
      return {
        ...state,
        stripeBalance: null,
      };

    default:
      return state;
  }
};

export default messageReducer;
