import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex; 
  padding: 20px 5px;
  font-size: 18px;
  font-weight: bold;
  background: ${props => props.theme.backround};
  color: ${props => props.theme.body};

  @media screen and (max-width: 800px) {
    justify-content: center;
    padding: 20px;
    font-size: 20px !important; 
  }
`;
