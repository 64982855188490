import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserSession } from './redux/actions/authActions';
import { getNotifications } from './redux/actions/messageActions';
import { getFavRentals } from './redux/actions/rentalActions';
import { useScrollToTop } from './hooks/useScrollToTop';
import { useTheme } from './features/dark-mode/ThemeContext';
import { networkDetector } from './components/network-detector/NetworkDetector';
import { AppWrapper } from './global.styles';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import {
  askToReceiveNotifications,
  getIncomingMessage,
} from './firebase/push-notification';

//components
import HomePage from './pages/home-page/home-page';
import Loader from './components/loader/CircleLoader';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Header from './components/header/Header';
import ModalManager from './modals/ModalManager';
import { Authenticated, BaseAuthenticated } from './components/route-guard';


//lazy loaded routes
const UserInventory = lazy(() =>
  import('./pages/user-inventory-page/UserInventory')
);
const StorePage = lazy(() => import('./pages/store-page/store-page'));
const RentalPage = lazy(() => import('./pages/rental-page/rental-page'));
const CheckoutPage = lazy(() => import('./pages/checkout-page/checkout-page'));
const SettingsPage = lazy(() => import('./pages/settings-page/SettingsPage'));
const InfoPage = lazy(() => import('./pages/info-page/InfoPage'));
const ProfilePage = lazy(() => import('./pages/profile-page/ProfilePage'));
const AboutPage = lazy(() => import('./pages/about-page/AboutPage'));
const ContactPage = lazy(() => import('./pages/contact-page/ContactPage'));
const NotFoundPage = lazy(() => import('./pages/not-found-page/NotFoundPage'));

function App() {
  useScrollToTop();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (!currentUser) return;
    askToReceiveNotifications(currentUser);
    getIncomingMessage();
    dispatch(getNotifications());
    dispatch(getFavRentals());
    dispatch(checkUserSession());
  }, [dispatch, currentUser]);

  const LoadingComponent = <Loader inverted={!theme?.dark} text='Loading' />;

  return (
    <AppWrapper>
      <Header />
      <ErrorBoundary>
        <Suspense fallback={LoadingComponent}>
          <ModalManager />
          <Route exact path='/' component={HomePage} />
          <Route
            path='/(.+)'
            render={() => (
              <Container>
                <Switch>
                  <Route
                    path='/contact'
                    component={Authenticated(ContactPage)}
                  />
                  <Route path='/about' component={AboutPage} />
                  <Route path='/info' component={InfoPage} />
                  <Route path='/rentals' component={RentalPage} />
                  <Route path='/checkout' component={CheckoutPage} />
                  <Route path='/store' component={Authenticated(StorePage)} />
                  <Route path='/settings' component={SettingsPage} />
                  <Route
                    path='/user_profile/:id'
                    component={BaseAuthenticated(ProfilePage)}
                  />
                  <Route
                    path='/user_inventory/:id'
                    component={BaseAuthenticated(UserInventory)}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </Container>
            )}
          />
    
        </Suspense>
      </ErrorBoundary>
    </AppWrapper>
  );
}

export default networkDetector(App);
