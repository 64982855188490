import React from 'react';
import { Confirm } from 'semantic-ui-react';

const ConfirmModal = (props) => {

  return (
    <Confirm
      dimmer={props.dimmer}
      open={true}
      cancelButton={props.cancelButtonText ? props.cancelButtonText : null}
      confirmButton={props.confirmButtonText ? props.confirmButtonText : null}
      onCancel={props.handleCancel}
      onConfirm={props.handleConfirm}
      header={props.header}
      size={props.size}
      content={<h4 style={props.style}> {props.content} </h4>}
    />
  );
};

export default ConfirmModal;
