import styled, { keyframes } from 'styled-components';
import { Segment } from 'semantic-ui-react';

const slideIn = keyframes`
from {
transform: translateX(0%);
}
to {
transform: translateX(-105%);
}
`;

const slideOut = keyframes`
from {
transform: translateX(-100%);
}
to {
transform: translateX(0%);
}
`;

export const DrawerHolder = styled.div`
  position: fixed;
  width: 350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: whitesmoke;
  top: 80px;
  right: -380px;
  z-index: 15;
  animation: ${(props) => (props.open ? slideIn : slideOut)} 0.3s forwards;
  visibility: ${(props) => !props.open && 'hidden'};
  transition: visibility 1s;
  @media screen and (max-width: 800px) {
    width: 340px;
    height: 100vh;
  }
`;

export const Banner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #21ba45;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  h3 {
    color: white;
    margin: 5px;
  }
`;

export const CustomSegment = styled(Segment)`
  margin-top: 50px !important;
  width: 95%;
`;

export const Span = styled.span`
  color: blueviolet; 
  cursor: pointer;
`

