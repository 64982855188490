import React from 'react';
import { CartContainer, ShoppingIcon, CountContainer } from './cart-icon.styles';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCartDropDown } from '../../redux/actions/cartActions';

const CartIcon = () => {
    
  const dispatch = useDispatch(); 
  const cartItem = useSelector(state => state.cart.cartItem)

  return (
    <CartContainer onClick={() => dispatch(toggleCartDropDown())}>
      <ShoppingIcon />
       <CountContainer>{cartItem ? 1 : 0}</CountContainer>
    </CartContainer>
  );
};

export default CartIcon;
