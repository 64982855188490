import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';
import { HeaderContainer } from './collection-container.styles';

//components
import Rental from '../collection-item/Rental';
import Thread from '../collection-item/Thread';


const CollectionContainer = (props) => {

  const transformedHeader = props.header?.replace(/^\w/, (fl) =>
    fl.toUpperCase()
  );
 
  return (
    <>
      {props.header && (
        <HeaderContainer> {transformedHeader} </HeaderContainer>
      )}
      <Grid 
        centered={props.centered}
        stackable
        doubling
        columns={props.columns}
      >
        {props.rentals?.map((rental) => (
          <Rental key={rental.id} {...rental} {...props} />
        ))}
        {props.messages?.map((message) => (
          <Thread key={message.id} {...message} {...props} />
        ))}
      </Grid>
    </>
  );
};

export default memo(CollectionContainer);
