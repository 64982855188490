import { combineReducers } from 'redux';
import cartReducer from './reducers/cartReducer';
import rentalsReducer from './reducers/rentalsReducer';
import authReducer from './reducers/authReducer';
import asyncReducer from './reducers/asyncReducer';
import modalReducer from './reducers/modalReducer';
import messageReducer from './reducers/messageReducer';
import transactionReducer from './reducers/transactReducer';
import { persistReducer } from 'redux-persist';
import { reducer as toastrReducer } from 'react-redux-toastr';
import storage from 'redux-persist/lib/storage';

const config = {
  key: 'root',
  storage,
  whitelist: ['cart', 'auth', 'rentals']
};

const appReducer = combineReducers({
  cart: cartReducer,
  rentals: rentalsReducer,
  modals: modalReducer,
  auth: authReducer,
  loading: asyncReducer,
  messages: messageReducer,
  transactions: transactionReducer, 
  toastr: toastrReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(config, rootReducer);
