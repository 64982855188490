import React from 'react';
import { MobileItem } from './header.styles';
import { Icon, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

const MobileMenuHolder = ({ items, handleMenuOpen, wishlistCount }) => {
  
    const history = useHistory();

  function handleRedirect(path) {
    return function () {
      history.push(path);
      handleMenuOpen();
    };
  }

  function render() {
    return items?.map((item) => {
      return (
        <div key={item.id}>
          <MobileItem onClick={handleRedirect(item.path)}>
            {item.name}
            {item.hasLabel && wishlistCount > 0 ? (
              <Label content={wishlistCount} color='teal' />
            ) : (
              <Icon name={item.icon} />
            )}
          </MobileItem>
        </div>
      );
    });
  }

  return <>{render()}</>;
};

export default MobileMenuHolder;
