import styled, { css } from 'styled-components';
import { Button, Item } from 'semantic-ui-react';

export const ItemHeader = styled(Item.Header)`
  color: ${(props) => props.theme.body} !important;
  cursor: pointer; 
  &:hover {
    color: blue !important; 
  }
`
export const ItemDescription = styled(Item.Description)`
  color: ${(props) => props.theme.body} !important;
`

export const CardContentContainer = styled.div`
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.body};
  cursor: pointer;
  padding: 10px;
  span {
    font-size: 14px !important;
    margin-top: 7px;
  };
`;

export const ImageHolder = styled.img`
  height: ${(props) => props.cardHeight || '235px'};
  object-fit: cover;
  &:hover {
    cursor: pointer;
    transform: scale(1.08);
    transition: transform 2s ease-in-out;
  };
  @media screen and (max-width: 800px){
      height: 250px;
  }
 
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`;

export const CustomButton = styled(Button)`
  font-weight: bolder !important;
  @media screen and (max-width: 800px) {
    background: green !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: white !important;
  }
`;

//functionality of CardInfoContainer based on if price exists
const CardInfoContainerBaseStyle = css`
  text-align: center;
  font-size: ${(props) => props.bold && '18px'} !important;
  span {
    font-weight: ${(props) => props.bold && '18px'} !important;
  }
`;

const CardInfoContainerStyleWithPrice = css`
  display: flex;
  margin-top: 20 !important;
  justify-content: space-between;
  span {
    @media screen and (max-width: 800px) {
      font-size: 16px !important;
    }
  }
`;

function getStyleForInfoContainer(props) {
  return props.price
    ? CardInfoContainerStyleWithPrice
    : CardInfoContainerBaseStyle;
}

export const CardInfoHolder = styled.div`
  ${getStyleForInfoContainer}
`;
