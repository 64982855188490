import React from 'react';
import { Label } from 'semantic-ui-react';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from '../../components/date-picker/DatePicker';

const DateInput = (props) => {
  const { control, errors } = useFormContext();

  const handleChange = ([selected]) => {
    return selected;
  };

  return (
    <>
      {props.label && <label> {props.label} </label>}
      <Controller
        as={
          <DatePicker
            {...props}
          />
        }
        control={control}
        name={props.name}
        valueName='selected'
        onChange={handleChange}
        rules={props.rules}
        defaultValue={props.value}
      />
      {errors[props.name] && (
        <Label style={{ marginTop: 0, zIndex: 0 }} pointing color='red'>
          {props.errMsg}
        </Label>
      )}
    </>
  );
};

export default DateInput;
