export const rentalTypes = {
  POPULATE_RENTAL: 'POPULATE_RENTAL',
  CLEAR_RENTAL: 'CLEAR_RENTAL',
  GET_FAV_RENTALS: 'GET_FAV_RENTALS', 
  ADD_FAV_RENTAL: 'ADD_FAV_RENTAL', 
  REMOVE_FAV_RENTAL: 'REMOVE_FAV_RENTAL'
};

export const transactActionTypes = {
  ADD_TRANSACTIONS: 'ADD_TRANSACTIONS',
  GET_TRANSACTION: 'GET_TRANSACTION',
};

export const msgActionTypes = {
  GET_USER_MESSAGES: 'GET_USER_MESSAGES',
  CLEAR_USER_MESSAGES: 'CLEAR_USER_MESSAGES', 
  GET_TRANSACT_COUNT: 'GET_TRANSACT_COUNT', 
  CLEAR_TRANSACT_COUNT: 'CLEAR_TRANSACT_COUNT',
  GET_BALANCE: 'GET_BALANCE', 
  CLEAR_BALANCE: 'CLEAR_BALANCE', 
  SET_FUNDS_LOAD: 'SET_FUNDS_LOAD', 
  END_FUNDS_LOAD: 'END_FUNDS_LOAD',
  SET_ERR_MSG: 'SET_ERR_MSG', 
  CLEAR_ERR: 'CLEAR_ERR'
};

export const asyncActionTypes = {
  ASYNC_ACTION_START: 'ASYNC_ACTION_START',
  ASYNC_ACTION_FINISH: 'ASYNC_ACTION_FINISH',
  ASYNC_ACTION_ERROR: 'ASYNC_ACTION_ERROR',
};

export const cartTypes = {
  TOGGLE_CART: 'TOGGLE_CART',
  ADD_ITEM: 'ADD_ITEM',
  CLEAR_CART: 'CLEAR_CART',
};

export const modalTypes = {
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
};

export const authTypes = {
  SET_AUTH_LOADING: 'SER_AUTH_LOADING',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT: 'SIGN_OUT',
};
