import { useState, useEffect } from 'react';

export const useAddToHomeScreen = () => {

  const [prompt, setPrompt] = useState(null);

  const init = () => {
    if (prompt) return prompt.prompt();
    return Promise.reject(new Error('Tried installing but did not succeed'));
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', ready);
    return () => window.removeEventListener('beforeinstallprompt', ready);
  }, []);

  return [prompt, init]
};
