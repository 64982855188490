import React from 'react';
import { Dropdown, Image } from 'semantic-ui-react';
import { useTheme, white, black } from '../../features/dark-mode/ThemeContext';

const Notifications = ({ authUser, handleSignOut, navigate }) => {
   
 const theme = useTheme(); 

 const color = theme.dark ? white: black;

  return (
    <>
      <Image
        avatar
        size='mini'
        spaced='right'
        src={authUser?.photoURL || '/images/user.png'}
      />
      <Dropdown inline pointing='top right' style={{ color }}>
        <Dropdown.Menu>
          <Dropdown.Item 
           onClick={() => navigate('/store')}
           text='My Store' 
           icon='shop'
          >
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => navigate('/store/owner-transactions')}
            text='My Transactions'
            icon='money'
          />
          <Dropdown.Item
            onClick={() => navigate('/store/customer-transactions')}
            text='My Orders'
            icon='cart'
          />
          <Dropdown.Item
            onClick={() => navigate('/settings')}
            text='Settings'
            icon='settings'
          />
          <Dropdown.Item text='Sign Out' icon='sign-out' onClick={handleSignOut} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Notifications;
