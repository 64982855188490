import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { useFormContext, Controller } from 'react-hook-form';

const Select = ({
  name,
  defaultValue,
  rules,
  toggle,
  errMsg,
  label,
  ...rest
}) => {
  const { errors, control } = useFormContext();

  const handleChange = ([, selected]) => {
    if (toggle) {
      toggle(selected.value);
    }
    return selected.value;
  };

  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Controller
        as={<Form.Select {...rest} />}
        name={name}
        rules={rules}
        control={control}
        onChange={handleChange}
        defaultValue={defaultValue}
        mode='onChange'
      />
      {errors[name] && (
        <Label style={{ marginTop: 0 }} pointing color='red'>
          {errMsg}
        </Label>
      )}
    </Form.Field>
  );
};

export default Select;
