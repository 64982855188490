import { useEffect } from 'react';

export const useClickOutside = (ref, callback, state) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target) && state) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
