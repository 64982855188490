import React, { useRef } from 'react';
import { Responsive, Icon } from 'semantic-ui-react';
import { useClickOutside } from '../../hooks/useClickOutside';
import {
  MobileMenuContainer,
  MobileMenuHolder,
  MobileItem,
  MenuItemButtonHolder,
} from './header.styles';
import MobileHolder from './MobileMenuHolder';
import ThemeSwitcher from '../theme-switcher/ThemeSwitcher';

const authorizedRoutes = [
  { id: 1, name: 'My store', path: '/store', icon: 'shop' },
  { id: 2, name: 'Settings', path: '/settings', icon: 'settings' },
  { id: 3, name: 'Search', path: '/rentals/search', icon: 'search' },
  { id: 4, name: 'Rent', path: '/rentals', icon: 'shopping bag' },
  {
    id: 5,
    name: 'Wishlist',
    path: '/rentals/wishlist',
    icon: 'heart',
    hasLabel: true,
  },
];

const publicRoutes = [
  { id: 6, name: 'Rent', path: '/rentals', icon: 'shopping bag' },
  { id: 7, name: 'Search', path: '/rentals/search', icon: 'search' },
  {
    id: 8,
    name: 'Wishlist',
    path: '/rentals/wishlist',
    icon: 'heart',
    hasLabel: true,
  },
];

const MobileMenu = ({
  authUser,
  open,
  handleMenuOpen,
  handleOpenModal,
  handleSignOut,
  wishlistCount,
}) => {
  const mobileRef = useRef();
  useClickOutside(mobileRef, handleMenuOpen, open);

  return (
    <Responsive maxWidth={800}>
      <MobileMenuContainer ref={mobileRef} open={open}>
        <MobileMenuHolder vertical>
          {authUser ? (
            <>
              <MobileItem style={{ cursor: 'auto' }}>
                Welcome {authUser.displayName}
                <Icon name='user' />
              </MobileItem>
              <MobileHolder
                handleMenuOpen={handleMenuOpen}
                items={authorizedRoutes}
                wishlistCount={wishlistCount}
              />
              <MobileItem onClick={handleSignOut}>
                Logout
                <Icon name='sign-out' />
              </MobileItem>
            </>
          ) : (
            <>
              <MobileItem onClick={handleOpenModal}>
                Login
                <Icon name='sign-in' />
              </MobileItem>
              <MobileHolder
                handleMenuOpen={handleMenuOpen}
                items={publicRoutes}
                wishlistCount={wishlistCount}
              />
            </>
          )}
          <MenuItemButtonHolder>
            <ThemeSwitcher />
          </MenuItemButtonHolder>
        </MobileMenuHolder>
      </MobileMenuContainer>
    </Responsive>
  );
};

export default MobileMenu;
