import React from 'react';
import { Segment, Divider, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import * as actions from '../../redux/actions/authActions';
import Form, { TextInput, Button, ResetButton } from '../form/Form';
import ErrorMessage from '../error-message/ErrorMessage';
import {
  ERROR,
  REGISTER_SCREEN,
  LOGIN_SCREEN,
  RESET_SCREEN,
} from '../../modals/LoginModal';

const LoginForm = ({ modalState, dispatch, reduxDispatch }) => {
  const loading = useSelector((state) => state.loading);

  const handleSubmit = async (data) => {
    const user = { ...data };
    try {
      switch (modalState.screen) {
        case 'Login':
          await reduxDispatch(actions.loginUser(user));
          break;
        case 'Register':
          await reduxDispatch(actions.registerUser(user));
          break;
        default:
          await reduxDispatch(actions.sendPasswordReset(user.email));
      }
    } catch (err) {
      dispatch({ type: ERROR, payload: { error: err.message } });
    }
  };

  const switchScreen = (type) => () => {
    if (type === 'login') dispatch({ type: REGISTER_SCREEN });
    if (type === 'reset') dispatch({ type: RESET_SCREEN });
    if (type === 'register') dispatch({ type: LOGIN_SCREEN });
  };

  const handleRemoveErr = () => {
    dispatch({ type: ERROR, payload: { error: null } });
  };

  const handleGoogleLogin = () => {
    reduxDispatch(actions.socialLogin());
  };

  const renderForm = () => {
    switch (modalState.screen) {
      case 'Reset password':
        return (
          <>
            <TextInput
              name='email'
              placeholder='Email'
              errMsg='Valid email required'
              autoComplete='off'
              handleRemoveErr={handleRemoveErr}
              rules={{
                required: 'required',
                pattern: {
                  value: /^[a-z\d.-]+@[a-z\d.-]+\.[a-z]{2,3}$/,
                },
              }}
            />
            <Button positive loading={loading} content='Submit' />
            <ResetButton
              type='button'
              handleFormChange={switchScreen('register')}
              disabled={loading}
              content='Go back'
            />
          </>
        );
      case 'Register':
        return (
          <>
            <TextInput
              name='userName'
              placeholder='Username'
              autoComplete='off'
              errMsg='Username required'
              rules={{ required: true }}
              handleRemoveErr={handleRemoveErr}
            />
            <TextInput
              name='email'
              placeholder='Email'
              errMsg='Valid email required'
              autoComplete='off'
              handleRemoveErr={handleRemoveErr}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-z\d.-]+@[a-z\d.-]+\.[a-z]{2,3}$/,
                },
              }}
            />
            <TextInput
              name='password'
              placeholder='Password'
              errMsg='Password must contain uppercase letter, special character and at least 6 characters'
              type='password'
              autoComplete='off'
              rules={{
                required: true,
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,16}$/,
                },
              }}
              handleRemoveErr={handleRemoveErr}
            />
            <Button positive loading={loading} content='Submit' />
            <ResetButton
              type='button'
              handleFormChange={switchScreen('register')}
              disabled={loading}
              content='Go back'
            />
          </>
        );
      default:
        return (
          <>
            <TextInput
              name='email'
              placeholder='Email'
              errMsg='Valid email required'
              autoComplete='off'
              handleRemoveErr={handleRemoveErr}
              rules={{
                required: 'required',
                pattern: {
                  value: /^[a-z\d.-]+@[a-z\d.-]+\.[a-z]{2,3}$/,
                },
              }}
            />
            <TextInput
              name='password'
              placeholder='Password'
              errMsg='Valid password is required'
              type='password'
              autoComplete='off'
              rules={{ required: true, minLength: 6 }}
              handleRemoveErr={handleRemoveErr}
            />
            <Button positive loading={loading} content='Submit' />
            <ResetButton
              type='button'
              handleFormChange={switchScreen('login')}
              disabled={loading}
              content='Register'
            />
          </>
        );
    }
  };

  return (
    <Segment>
      <Form size='large' onSubmit={handleSubmit}>
        {renderForm()}
        <ErrorMessage centered />
        <Divider />
        <Button
          style={{ marginBottom: '10px' }}
          type='button'
          fluid
          color='google plus'
          onClick={handleGoogleLogin}
        >
          <Icon name='google plus' />
          Login with Google
        </Button>
        <Button
          style={{ marginBottom: '10px' }}
          fluid
          color='teal'
          type='button'
          content='Forgot password'
          onClick={switchScreen('reset')}
        />
      </Form>
    </Segment>
  );
};

export default LoginForm;
