import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingComponent = ({inverted, text}) => {
  return (
    <Dimmer inverted={inverted} active> 
       <Loader content={text}/>
    </Dimmer>
  ); 
}

export default LoadingComponent;