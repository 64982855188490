import React, { useContext, createContext, useMemo } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useDarkMode } from '../../hooks/useDarkMode';
import theme, { black, white, grey } from './theme.js';


const ThemeContext = createContext();
const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {

  const [themeState, setThemeState] = useDarkMode();

  const memoizedState = useMemo(
    () => ({
      toggle: () => {
        const dark = !themeState.dark;
        localStorage.setItem('dark', JSON.stringify(dark));
        setThemeState({ ...themeState, dark });
      },
      ...themeState,
    }),
    [themeState, setThemeState]
  );

  if (!themeState.hasMounted) {
    return <div />;
  }

  const computedTheme = themeState.dark ? theme('dark') : theme('light');

  return (
    <StyledThemeProvider theme={computedTheme}>
      <ThemeContext.Provider
        value={memoizedState}
      >
        {children}
      </ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

export { ThemeProvider, useTheme };
export { black, white, grey };