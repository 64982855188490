import { asyncActionStart, asyncActionFinish } from './asyncActions';
import { msgActionTypes } from '../types';
import { toastr } from 'react-redux-toastr';
import { db, serverTimestamp } from '../../firebase';
/**
 * @summary resets notifications for each user
 * @param {String} ownerId 
 * @param {Object} transaction
 * @returns void
 */
export const resetNotifications = (ownerId, transactionId) => async (dispatch) => {
  if (!ownerId || !transactionId) return;
  const userDocRef = db.doc(`users/${ownerId}`);
  const transactDocRef = db.doc(`transactions/${transactionId}`);
  try {
    const snap = await userDocRef.get();
    if (!snap.exists || !snap.data()?.messages) return;
    await userDocRef.update({ transactions: null });
    await transactDocRef.update({status: 'open'});
    dispatch({ type: msgActionTypes.CLEAR_TRANSACT_COUNT });
  } catch (err) {
    toastr.error('Oops', 'Something went wrong with notifications.');
  }
};
/**
 * @summary - sets up first notification message
 * @param  {Object} data - must have transactionId, customerUid properties
 * @returns {Toastr} - toastr success | error
 */
export const notifyRenter = (data) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (!user) return;
  const author = user.displayName;
  const transactionId = data.transactionId;
  const customerUid = data.customerUid;
  const text = data.text;
  const transactionDocRef = db.doc(`transactions/${transactionId}`);
  const messageSubCol = db
    .doc(`transactions/${transactionId}`)
    .collection('messages');
  try {
    dispatch(asyncActionStart());
    const snap = await transactionDocRef.get();
    if (snap.data()?.status === 'closed') {
      dispatch(asyncActionFinish());
      return toastr.info('Info', 'This transaction is closed. SubRent cannot send a pick-up address.');
    }
    await messageSubCol.add({
      author,
      recipientId: customerUid,
      text: `Your pick up is confirmed at ${text}.`,
      date: serverTimestamp(),
      image: user.photoURL,
    });
    dispatch(asyncActionFinish());
    return toastr.success('Success', 'Confirmation is sent.');
  } catch (err) {
    dispatch(asyncActionFinish());
    return toastr.error('Oops', 'Something went wrong with notification.');
  }
};
