import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';

const RadioInput = ({ initialValue = 'yes', name, ...rest }) => {

  const [value, setValue] = useState(initialValue);
  const { control } = useFormContext();

  const handleChange = ([, props]) => {
      setValue(props.value);
      return props.value;
  }

  return (
    <>
      <Controller
        as={
          <Form.Radio
            label='Yes'
            value='yes'
            checked={value === 'yes'}
            {...rest}
          />
        }
        defaultValue={value}
        valueName={value}
        name={name}
        control={control}
        onChange={handleChange}
      />
      <Controller
        as={
          <Form.Radio
            label='No'
            value='no'
            checked={value === 'no'}
            {...rest}
          />
        }
        defaultValue={value}
        valueName={value}
        name={name}
        control={control}
        onChange={handleChange}
      />
    </>
  );
};

export default RadioInput;
