import { cartTypes } from '../types';

export const addItemToCart = (item) => ({
  type: cartTypes.ADD_ITEM,
  payload: { item },
});

export const clearCart = () => ({
  type: cartTypes.CLEAR_CART,
});

export const toggleCartDropDown = () => ({
  type: cartTypes.TOGGLE_CART,
});


