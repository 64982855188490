import React from 'react';
import { ButtonContainer, CustomButton } from './collection-item.styles';

const ButtonHolder = (props) => {
  let buttonName;
  let color = null;
  if (props.confirmButton || props.viewButton || props.ordersButton) {
    buttonName = 'View';
  }
  if (props.exploreButton) {
    buttonName = 'Explore';
    color = 'green';
  }
  if (props.manageButton) {
    buttonName = 'Manage';
  }
  return (
    <ButtonContainer onClick={props.handleRedirect}>
      <CustomButton color={color}>{buttonName}</CustomButton>
    </ButtonContainer>
  );
};

export default ButtonHolder;
