import { cartTypes } from '../types';

const initialState = {
  cartVisibility: false,
  cartItem: null,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartTypes.ADD_ITEM:
      return {
        ...state,
        cartItem: action.payload.item,
      };
    case cartTypes.CLEAR_CART:
      return {
        ...state,
        cartItem: null,
      };

    case cartTypes.TOGGLE_CART:
      return {
        ...state,
        cartVisibility: !state.cartVisibility,
      };

    default:
      return state;
  }
};

export default cartReducer;
