import styled from 'styled-components'; 


export const NetworkMessageContainer = styled.div`
position: absolute;
top: 15%;
left: 5%;
z-index: 100;
@media screen and (max-width:800px){
width: 70%;
top: 10%;
}
`