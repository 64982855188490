import styled from 'styled-components';

export const CartItemContainer = styled.div`
  width: 100%;
  height: 85px;
  margin-bottom: 15px;
  display: flex; 
  flex-direction: row;
`;
export const ItemColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const CartItemImage = styled.img`
  width: 75%;
  height: 80%;
  border: 1px solid grey;
`;
export const CartButtonContainer = styled.div`
  margin-top: 4px;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;