import React, { useState } from 'react';
import { Modal, Button, Icon, Header } from 'semantic-ui-react';
import { Paragraph } from '../global.styles';
import { closeModal } from '../redux/actions/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { createStripeSession } from '../redux/actions/stripeActions';
import ErrMessage from '../components/error-message/ErrorMessage';

const AgreementModal = ({ duration, fromDate, price_choice }) => {
  
  const dispatch = useDispatch();
  const cartItem = useSelector((state) => state.cart.cartItem);
  const [isLoading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleRedirectToStripe = async () => {
    try {
      setLoading(true);
      const { sessionId } = await createStripeSession({
        duration,
        cartItem,
        fromDate,
        price_choice,
      });
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId });
      setLoading(false);
    } catch (err) {
      if (err.message === 'INTERNAL') {
        setLoading(false);
        return setErr(
          'Oops there was a technical issue. Please contact us via support if this situation persists'
        );
      }
      setLoading(false);
      setErr(err.message);
    }
  };

  return (
    <Modal open={true} onClose={() => dispatch(closeModal('AgreementModal'))}>
      <Modal.Header style={{ textAlign: 'center' }}>
        <h2> Agreement</h2>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>
            <h3> Covid-19 Pandemic </h3>
          </Header>
          <Paragraph>
            In order to prevent the spread of Covid-19. It is the responsibility
            of the owners and customers to ensure that equipment is disinfected
            after use. Please follow the advice of Canadian health officals in
            doing so. Let’s do our part in stopping the pandemic.
          </Paragraph>
          <Header>
            <h3> Responsibility for Damage or Loss </h3>
          </Header>
          <Paragraph>
            It is your responsibility to cover any damages, theft or loss of the
            equipment. SubRent is not liable for damages or loss. Agreement is
            between the owner and you and SubRent provides technology to
            facilitate the transaction. Howevever, in case of unlawful action,
            we will help with whatever is necessary to ensure it does not happen
            again.
          </Paragraph>
          <Header>
            <h3> Equipment Renting Period </h3>
          </Header>
          <Paragraph>
            Please note that half a day rental is 12 hours and full day rental is 24
            hours. The rental time starts when you receive equipment from the owner.
            Rentals can only be kept for the duration paid. In some cases, the
            rental can be extended upon agreement with the owner. You can reach out 
            to the owner via the SubRent chat. 
          </Paragraph>
          <Header>
            <h3> Delivery Options</h3>
          </Header>
          <Paragraph>
            In the rental page, you can see if the owner is able to deliver. It
            is the responsibility of the customer to contact the owner and agree
            if it should be delivered or picked up. Please reach out to the
            owner to see if they are willing to deliver equipment for you.
          </Paragraph>
          <Header>
            <h3> Rental Period Start </h3>
          </Header>
          <Paragraph>
            The rental period starts on the day and time that the rental is
            picked up or delivered. The time and place of this meeting are
            worked out between the owner and customer directly in the app.
          </Paragraph>
          <Header>
            <h3> Rental End Period </h3>
          </Header>
          <Paragraph>
            The rental period ends on the day that the rented equipment is
            returned and transaction is closed by the owner. Please contact the
            other party to schedule a convenient time/place for drop-off and
            make sure you leave time to thoroughly check the equipment. After
            the equipment is returned and the owner has confirmed their
            equipment is accounted for and in good working order, they must
            close the transactios in the transactions page.
          </Paragraph>
          <Header>
            <h3> Rental Cancellation </h3>
          </Header>
          <Paragraph>
            We understand plans change, and sometimes you may need to cancel a
            rental. If you need to cancel, the first step is to let the owner
            know. Give as much notice as possible, so the owner can plan for it.
            Cancellations can be disruptive for both customers and owners. So
            please do your best to avoid this situation as there might be fees
            incurred for refunding your money.
          </Paragraph>
          <Header>
            <h3> SubRent Fee </h3>
          </Header>
          <Paragraph>
            SubRent will charge you a 10% fee on this transaction. This fee
            helps us with credit card prossessing, banking services and app
            maitenance costs. This will also help us continue to develop new
            features and offerings for our members.
          </Paragraph>
          <Header>
            <h3> Rental Extension </h3>
          </Header>
          <Paragraph>
            You can contact the owner and request to extend your rental. It is
            the responsibility of the customer to work out an agreement with the
            rental owner.
          </Paragraph>
        </Modal.Description>
      </Modal.Content>
      <ErrMessage centered err={err} />
      <Modal.Actions>
        <div>
          <p style={{ fontWeight: 'bold' }}>
            By proceeding further, you agree to the terms of service.{' '}
          </p>
          <Button primary loading={isLoading} onClick={handleRedirectToStripe}>
            Proceed further <Icon name='right chevron' />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default AgreementModal;
