import React from 'react';
import { Label } from 'semantic-ui-react';

const LabelComponent = ({ status, distance }) => {
  function renderLabel() {

    switch (status) {
      case 'new':
        return (
          <Label attached='top left' tag color='red' size='tiny'>
            New
          </Label>
        );
      case 'inactive':
        return (
          <Label attached='top left' tag color='black' size='tiny'>
            Inactive
          </Label>
        );
      case 'rented':
        return (
          <Label attached='top left' tag color='green' size='tiny'>
            Rented
          </Label>
        );
      case 'pending':
        return (
          <Label attached='top left' tag color='teal' size='tiny'>
            Coming soon
          </Label>
        );
      default:
        break;
    }
  }
  return (
    <>
      {distance && (
        <Label attached='top right' color='grey' size='tiny'>
          {distance} km
        </Label>
      )}
      {renderLabel()}
    </>
  );
};

export default LabelComponent;
