import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form, Label } from 'semantic-ui-react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { Segment, List, Input } from 'semantic-ui-react';
import cuid from 'cuid';

function PlacesInput({ name, errMsg, defaultValue = '', ...rest }) {
  const hook = useFormContext();

  const {
    ready,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['(regions)'],
      componentRestrictions: { country: 'can' },
    },
    debounce: 500,
  });

  const handleChange = ([, selected]) => {
    setValue(selected.value);
    return selected.value;
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    hook.setValue('region', description);
    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <List.Item key={id || cuid()} onClick={handleSelect(suggestion)}>
          <List.Header>{main_text}</List.Header>
          <List.Description>{secondary_text}</List.Description>
        </List.Item>
      );
    });

  return (
    <Form.Field>
      <Controller
        as={<Input loading={loading} disabled={!ready} {...rest} />}
        name={name}
        onChange={handleChange}
        control={hook.control}
        defaultValue={defaultValue}
        rules={{ required: true }}
        mode='onChange'
      />
      <div style={{ marginTop: 12 }}>
        {hook.errors[name] && (
          <Label style={{ marginTop: 0 }} pointing color='red'>
            {errMsg}
          </Label>
        )}
      </div>
      {data.length > 0 && (
        <Segment
          style={{
            marginTop: 0,
            position: 'absolute',
            zIndex: 15,
            width: '100%',
            padding: 5,
          }}
        >
          <List selection> {status === 'OK' && renderSuggestions()}</List>
        </Segment>
      )}
    </Form.Field>
  );
}

export default PlacesInput;
