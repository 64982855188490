import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { useFormContext, Controller } from 'react-hook-form';

const Checkbox = ({ value, name, label, onChange, style = { marginLeft: '5px'}, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      as={<SemanticForm.Checkbox value={value} style={style} {...rest} />}
      name={name}
      label={label}
      control={control}
      onChange={onChange}
      valueName='selected'
    />
  );
};

export default Checkbox;
