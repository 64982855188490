import { asyncActionTypes } from '../types';

export const asyncActionStart = () => ({
  type: asyncActionTypes.ASYNC_ACTION_START
});

export const asyncActionFinish = () => ({
  type: asyncActionTypes.ASYNC_ACTION_FINISH
});
export const asyncActionError = () => ({
  type: asyncActionTypes.ASYNC_ACTION_ERROR
});
