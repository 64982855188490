import React from 'react';
import { Link } from 'react-router-dom';
import { FooterContainer, MobileItemHolder } from './footer.styles';
import { Icon, Grid, Divider, List, Responsive } from 'semantic-ui-react';

const Footer = () => {
  return (
    <FooterContainer>
      <Grid centered padded>
        <Grid.Row>
          <Responsive {...Responsive.onlyMobile}>
            <Grid.Column>
              <List size='massive'>
                <List.Item as={Link} to='/about'>
                  <MobileItemHolder>About Us </MobileItemHolder>
                </List.Item>
                <List.Item as={Link} to='/info/app'>
                  <MobileItemHolder>Advantages </MobileItemHolder>
                </List.Item>
                <List.Item as={Link} to='/info'>
                  <MobileItemHolder>Terms of Service </MobileItemHolder>
                </List.Item>
                <List.Item as={Link} to='/info/app'>
                  <MobileItemHolder>How It Works </MobileItemHolder>
                </List.Item>
                <List.Item as={Link} to='/contact'>
                  <MobileItemHolder>Contact Us </MobileItemHolder>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column style={{ marginTop: 20 }}>
              <a
                href='https://www.facebook.com/SubRent-100133195124834/'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Facebook'
              >
                <Icon size='huge' name='facebook' />{' '}
              </a>
            </Grid.Column>
          </Responsive>
        </Grid.Row>
        <Grid.Row columns={4} style={{ padding: 0 }}>
          <Grid.Column>
            <Responsive {...Responsive.onlyComputer}>
              <List link>
                <List.Item as={Link} to='/about'>
                  About Us
                </List.Item>
                <List.Item as={Link} to='/info/app'>
                  Advantages 
                </List.Item>
                <List.Item as={Link} to='/info'>
                  Terms of Service
                </List.Item>
              </List>
            </Responsive>
          </Grid.Column>
          <Grid.Column>
            <Responsive {...Responsive.onlyComputer}>
              <List link>
                <List.Item as={Link} to='/info/app'>
                  How It Works
                </List.Item>
                <List.Item as={Link} to='/contact'>
                  Contact Us
                </List.Item>
              </List>
            </Responsive>
          </Grid.Column>
          <Responsive {...Responsive.onlyComputer}>
            <Grid.Column>
              <a
                href='https://www.facebook.com/SubRent-100133195124834/'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Facebook'
              >
                <Icon size='big' name='facebook' />{' '}
              </a>
            </Grid.Column>
          </Responsive>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <p> &copy; Subrent {new Date().getFullYear()}</p>
        </Grid.Row>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
