import { useEffect, useState } from 'react';

export const useDarkMode = () => {
    const [themeState, setThemeState] = useState({
      dark: false,
      hasMounted: false
    });
    useEffect(() => {
      const stored = localStorage && localStorage.getItem('dark') === 'true';
      setThemeState({ dark: stored, hasMounted: true });
    }, []);
  
    return [themeState, setThemeState];
  };