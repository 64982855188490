import { rentalTypes } from '../types';
import { db } from '../../firebase';
import { toastr } from 'react-redux-toastr';

//helper functions
function resolvePromises(arr, docRef, user) {
  if (arr.length === 0) return;
  const promises = [];
  arr.forEach((rental) => {
    const p = docRef
      .doc(`${user.uid}_${rental.id}`)
      .set({ ownerUid: user.uid, ...rental });
    promises.push(p);
  });
  return Promise.all(promises);
}

export const getFavRentals = () => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  const existingRentals = getState().rentals.favRentals;
  if (!user) return;
  const wishListItemsDocRef = db.collection('wishlist-items');
  const wishListItemsQuery = wishListItemsDocRef.where(
    'ownerUid',
    '==',
    user.uid
  );
  try {
    const snapshot = await wishListItemsQuery.get();
    if (existingRentals?.length && existingRentals.length > 0 && !snapshot.empty) {
      const dbRentals = [];
      snapshot.forEach((dbRental) => {
        dbRentals.push({ id: dbRental, ...dbRental.data() });
      });
      //iterate thru exisitng array and remove duplicates if rentals exist in database
      const filteredRentals = existingRentals.filter((rental) => {
        return !dbRentals.some((dbRental) => dbRental.id === rental.id);
      });
      resolvePromises(filteredRentals, wishListItemsDocRef, user);
    } else if (existingRentals?.length && existingRentals.length > 0) {
      resolvePromises(existingRentals, wishListItemsDocRef, user);
    }
    const finalSnapshot = await wishListItemsQuery.get();
    if (!finalSnapshot.empty) {
      const rentals = [];
      finalSnapshot.forEach((rental) => {
        rentals.push({ id: rental.id, ...rental.data() });
      });
      dispatch({ type: rentalTypes.GET_FAV_RENTALS, payload: { rentals } });
    }
  } catch (err) {
    toastr.error('Oops', 'Could not fetch your wishlist items.');
  }
};

export const addFavRental = (rental) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  const rentals = getState().rentals?.favRentals || null;
  if (rentals && rentals?.length) {
    const existingRental = rentals.findIndex((r) => r.id === rental.id);
    if (existingRental !== -1) {
      toastr.info('Info', 'You already have this rental in your wishlist.');
      return;
    }
  }
  if (user) {
    try {
      const wishListItemsDocRef = db
        .collection('wishlist-items')
        .doc(`${user.uid}_${rental.id}`);
      const snapshot = await wishListItemsDocRef.get();
      if (snapshot.exists) {
        toastr.info('Info', 'You already have this rental in your wishlist.');
        return;
      }
      await wishListItemsDocRef.set({ ownerUid: user.uid, ...rental });
      dispatch({ type: rentalTypes.ADD_FAV_RENTAL, payload: { rental } });
    } catch (err) {
      toastr.error('Oops', 'Could not add rental to wishlist');
    }
  } else {
    dispatch({ type: rentalTypes.ADD_FAV_RENTAL, payload: { rental } });
  }
};

export const removeFavRental = (rentalId) => async (dispatch, getState) => {
  const user = getState().auth.currentUser;
  if (user) {
    try {
      const wishListItemsDocRef = db
        .collection('wishlist-items')
        .doc(`${user.uid}_${rentalId}`);
      await wishListItemsDocRef.delete();
      dispatch({ type: rentalTypes.REMOVE_FAV_RENTAL, payload: { rentalId } });
    } catch (err) {
      toastr.error('Oops', 'Could not delete rental from wishlist');
    }
  } else {
    dispatch({ type: rentalTypes.REMOVE_FAV_RENTAL, payload: { rentalId } });
  }
};

export const populateRental = (rentalItem) => ({
  type: rentalTypes.POPULATE_RENTAL,
  payload: { rentalItem },
});

export const clearRental = () => ({ type: rentalTypes.CLEAR_RENTAL });
