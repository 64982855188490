import React from 'react';
import { useSelector } from 'react-redux';
import LoginModal from './LoginModal';
import ConfirmModal from './ConfirmModal';
import ContactModal from './ContactModal';
import AgreementModal from './AgreementModal'; 

const modalLookup = {
  LoginModal,
  ConfirmModal, 
  ContactModal, 
  AgreementModal,
};

const ModalManager = () => {
  
  const currentModal = useSelector(state => state.modals);

  let renderedModal;
  if (currentModal) {    
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <div>{renderedModal}</div>;
} 

export default ModalManager;
 