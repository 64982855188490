import { transactActionTypes } from '../types';

const initialState = {
  transactions: [],
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactActionTypes.ADD_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.transactions,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
