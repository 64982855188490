import React, { memo } from 'react';
import { Grid, Card, Image, Divider } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

//components
import Label from './Label';
import ButtonHolder from './ButtonHolder';
import Rating from '../rating/Rating';
import {
  ImageHolder,
  CardInfoHolder,
  CardContentContainer,
} from './collection-item.styles';

const CollectionItem = (props) => {
  const history = useHistory();

  const handleRedirect = () => {
    if (props.path === '/rentals/categories') {
      return history.push(`${props.path}/${props.category}`);
    }
    history.push(`${props.path}/${props.id}`);
  };

  return (
    <Grid.Column>
      <Card centered>
        {props.status === 'closed' && (
          <Image
            fluid
            label={{ corner: 'right', icon: 'lock', color: 'black' }}
          />
        )}
        {props.status === 'open' && (
          <Image
            fluid
            label={{ corner: 'right', icon: 'unlock', color: 'green' }}
          />
        )}
        <ImageHolder
          cardHeight={props.cardHeight}
          alt='Rental image'
          src={props.image || '/images/fallback.png'}
        />
        <Label status={props.status} distance={props.distance} />
        {props.withRating && (
          <Rating
            style={{
              position: 'absolute',
              top: '58%',
              left: '10%',
              width: '80%',
            }}
            avgRating={props.avgRating}
            disabled
          />
        )}
        <CardContentContainer>
          <CardInfoHolder>
            {props.bold ? <h3> {props.name}</h3> : <h4> {props.name} </h4>}
          </CardInfoHolder>
          {props.full_day_price && (
            <CardInfoHolder price={true}>
              <span>
                Full day: ${parseInt(props.full_day_price).toFixed(2)}
              </span>
              <span>
                Half day: ${parseInt(props.half_day_price).toFixed(2)}
              </span>
            </CardInfoHolder>
          )}
          {!props.custAmt && !props.ownerAmt ? (
            <Divider />
          ) : (
            <CardInfoHolder>
              Amount{' '}
              <span>
                $
                {props.identifier === 'customer'
                  ? props.custAmt
                  : props.ownerAmt}
              </span>
            </CardInfoHolder>
          )}
          <ButtonHolder handleRedirect={handleRedirect} {...props} />
        </CardContentContainer>
      </Card>
    </Grid.Column>
  );
};

export default memo(CollectionItem);
