import styled, { css } from 'styled-components';
import { Label } from 'semantic-ui-react'

const baseStyle = css`
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 5px;
`;

const centeredStyle = css`
  display: flex; 
  color: red;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px;
`

const getStyle = props => {
  if (props.centered) {
    return centeredStyle;
  }
  return baseStyle;
};

export const ErrorMessageContainer = styled.div`
 ${getStyle}
`;

export const MessageLabel = styled(Label)`
font-size: 14px !important; 
`
