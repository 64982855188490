import { db, functions } from './index';
import { toastr } from 'react-redux-toastr';
/**
 *
 * @summary gets all rentals by search text that user types
 * @param  {String} searchText
 * @returns array of rental items found
 */
export const searchRentals = async (searchText) => {
  if (!searchText) return;
  const lowerCaseSearchText = searchText.toLowerCase();
  const rentalsColRef = db
    .collection('rentals')
    .where('search_fields', 'array-contains', lowerCaseSearchText)
    .limit(8);
  try {
    const snap = await rentalsColRef.get();
    if (snap.empty) return;
    let fetchedSearchRentals = [];
    snap.forEach((doc) => {
      fetchedSearchRentals.push({ id: doc.id, ...doc.data() });
    });
    return fetchedSearchRentals;
  } catch (err) {
    toastr.error('Oops', 'Failed to find anything');
  }
};

/**
 * sends a user question to a cloud function handling gateway to dialog flow
 * @param  {userText: String}
 * @return {Object} { response: string}
 */

export const chatWithAgent = async (userText) => {
  const chatWithBot = functions.httpsCallable('chatWithBot');
  const { data } = await chatWithBot({ userText });
  return data;
};
