import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { grey, black } from '../../features/dark-mode/theme';

export const MobileMenuContainer = styled.div`
  position: fixed;
  border-radius: 20% !important;
  top: 100px;
  left: 0px;
  width: 50%;
  padding-left: 5px;
  width: 200px !important;
  z-index: 2;
  visibility: ${(props) => !props.open && 'hidden'};
  transition: visibility 1s;
  animation: ${(props) => (props.open ? 'slideIn' : 'slideOut')} 0.3s forwards;
  @keyframes slideIn {
    from {
      transform: translateX(-450px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-450px);
    }
  }
`;

export const MobileMenuHolder = styled(Menu)`
  background: ${(props) =>
    props.theme.background === 'white' ? black : grey} !important;
`;

export const MobileItem = styled(Menu.Item)`
  color: white !important;
  padding: 10px 15px !important;
  display: flex !important;
  justify-content: space-between !important;
  &:hover {
    background: whitesmoke !important;
    color: black !important;
    border: none;
  }
  @media screen and (max-width: 800px) {
    background: black !important;
    cursor: pointer;
  }
`;

export const MenuItemButtonHolder = styled(Menu.Item)`
  color: white !important;
  padding: 10px 15px !important;
  display: flex !important;
  justify-content: space-between !important;
  @media screen and (max-width: 800px) {
    background: black !important;
    cursor: pointer;
  }
`;

export const MenuItemContainer = styled.span`
  font-size: 17px;
  color: ${(props) => props.theme.body};
  font-weight: bold;
  padding-right: 30px;
  cursor: pointer;
  &:hover {
    color: grey;
  }
`;

export const MobileBurger = styled.div`
  @media screen and (max-width: 800px) {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    span {
      width: 2rem;
      height: 0.4rem;
      background: ${(props) =>
        props.theme.background === 'white'
          ? 'rgb(16, 16, 16)'
          : 'rgb(255, 255, 255)'};
      border-radius: 10px;
      display: block;
      margin: 0 0 4px;
    }
  }
`;
