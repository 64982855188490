import { asyncActionTypes } from '../types';

const loading = false;

const asyncActionReducer = (state = loading, action) => {
  switch (action.type) {
    case asyncActionTypes.ASYNC_ACTION_START:
      return true;
    case asyncActionTypes.ASYNC_ACTION_FINISH:
    case asyncActionTypes.ASYNC_ACTION_ERROR:
      return false;
    default:
      return state;
  }
};

export default asyncActionReducer;
