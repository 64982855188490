import { differenceInDays, isBefore } from 'date-fns';
/**
 * Takes two dates and calculates
 * total, subtotal, numOfDates based on a number of dates
 * @param  {Date} startDate
 * @param  {Date} endDate
 * @param  {Number} price
 * @returns {Object} {subtotal: number, total: number, numOfDays:number }
 */

export function calculate(startDate, endDate, price = 0) {
  let duration, total, subtotal; 
  const isBeforeToday = isBefore(startDate, new Date(new Date().setHours(0, 0, 0, 0)));
  if (isBeforeToday) return { subtotal: 0, total: 0, duration: 0 };
  duration = differenceInDays(endDate, startDate);
  if (isNaN(duration)) duration = 0;
  subtotal = duration * price;
  if (Math.sign(subtotal) === -1) subtotal = 0;
  total = subtotal * 1.15 || 0;
  return { subtotal, total, duration };
}

/**
 * @param  {string} str
 * @param  {string} price
 * @returns array
 */
export function convertToSearchFields(str, price1, price2) {
  if (typeof str !== 'string' || typeof price1 !== 'string' || typeof price2 !== 'string') {
    return new Error('Invalid parameter types');
  }
  let searchFields = [];
  let strChunks = [];
  const lowerCaseText = str.toLowerCase().trim();
  //if name contains multiple words, split them up
  if (lowerCaseText.indexOf(' ') > 0) {
    strChunks = lowerCaseText.split(' ');
    //include the split word parts along with original word with 2 sets of price
    searchFields.push(...strChunks, lowerCaseText, price1, price2);
    return searchFields;
  }
  //if name contains one word, slice it into 2 for easy search
  const mid = Math.ceil(lowerCaseText.length / 2);
  const s1 = lowerCaseText.slice(0, mid);
  const s2 = lowerCaseText.slice(mid);
  searchFields.push(s1, s2, lowerCaseText, price1, price2);
  return searchFields;
}

export function convertToObj(arr) {
  arr.reduce((acc, cur) => {
    acc[cur.id] = cur.id;
    return acc;
  }, {});
}

export function resetObjVals(obj) {
  Object.keys(obj).forEach((k) => (obj[k] = ''));
  return obj;
}

export function normalizePhone(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)})${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
}

export function normalizeRouting(value) {
  if (!value) return value;
  const curVal = value.replace(/[^\d]/g, '');
  const cvLength = curVal.length;
  if (cvLength < 6) return curVal.slice(0, 5);
  return `${curVal.slice(0, 5)}-${curVal.slice(5, 9)}`;
} 
export function normalizePostalCode(value) {
  if (!value) return value;
  const normalized = value.replace(/[^a-z0-9]/gi, '').toUpperCase();
  return normalized.slice(0, 6);
  
} 
export function normalizeBankAccount(value) {
  const curVal = value.replace(/[^\d]/g, '');
  return curVal.slice(0, 12);
} 



/**
 * Calculates the distance, in kilometers, between two locations, via the
 * Haversine formula. Note that this is approximate due to the fact that
 * the Earth's radius varies between 6356.752 km and 6378.137 km.
 *
 * @param {Object} origin The first location given as .latitude and .longitude
 * @param {Object} dest The second location given as .latitude and .longitude
 * @return {number} The distance, in kilometers, between origin and dest fixed to 2 decimals.
 */


export function calculateDistance(origin, dest) {
    const radius = 6371; // Earth's radius in kilometers
    const latDelta = degreesToRadians(dest.latitude - origin.latitude);
    const lonDelta = degreesToRadians(dest.longitude - origin.longitude);
  
    const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
            (Math.cos(degreesToRadians(origin.latitude)) * Math.cos(degreesToRadians(dest.latitude)) *
            Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2));
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return (radius * c).toFixed(2);
  }

  //helper to turn degrees to redians
function degreesToRadians(degrees) {
    return (degrees * Math.PI)/180
}
