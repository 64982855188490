import React from 'react';
import { Header, Icon, Grid, Container } from 'semantic-ui-react';
import { SegmentContainer } from '../../global.styles';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasErrored: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <Grid centered>
          <Grid.Column>
            <Container>
              <SegmentContainer placeholder>
                <Header icon>
                  <Icon name='broken chain' color='green' />
                   <h4> Oops something went wrong. Please refresh the page ! </h4>
                </Header>
              </SegmentContainer>
            </Container>
          </Grid.Column>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
