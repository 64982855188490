import React, { useReducer } from 'react';
import { Modal } from 'semantic-ui-react';
import { closeModal } from '../redux/actions/modalActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginForm from '../components/login-form/LoginForm';
import ErrorMessage from '../components/error-message/ErrorMessage';

export const REGISTER_SCREEN = 'REGISTER_SCREEN';
export const LOGIN_SCREEN = 'LOGIN_SCREEN';
export const RESET_SCREEN = 'RESET_SCREEN';
export const ERROR = 'ERROR';

function reducer(state, action) {
  switch (action.type) {
    case RESET_SCREEN:
      return {
        ...state,
        screen: 'Reset password',
      };
    case LOGIN_SCREEN:
      return {
        ...state,
        screen: 'Login',
      };
    case REGISTER_SCREEN:
      return {
        ...state,
        screen: 'Register',
      };
    case ERROR:
      return {
        ...state,
        err: action.payload.error,
      };
    default:
      return state;
  }
}

const initalState = {
  screen: 'Login',
  err: null,
};

const LoginModal = ({ dimmer }) => {
    
  const [state, dispatch] = useReducer(reducer, initalState);
  const reduxDispatch = useDispatch();
  const { location, goBack, push } = useHistory();
  
  const handleCloseModal = () => {
    if (location.pathname === '/checkout' || location.pathname === '/') {
      reduxDispatch(closeModal());
    } else if (location.pathname.includes('/user_profile')) {
      goBack();
      reduxDispatch(closeModal());
    } else {
      push('/');
      reduxDispatch(closeModal());
    }
  };

  return (
    <Modal dimmer={dimmer} size='mini' open={true} onClose={handleCloseModal}>
      <Modal.Header>{state.screen}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <LoginForm
            modalState={state}
            dispatch={dispatch}
            reduxDispatch={reduxDispatch}
          />
        </Modal.Description>
        <ErrorMessage centered err={state.err} />
      </Modal.Content>
    </Modal>
  );
};

export default LoginModal;
