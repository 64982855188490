import React from 'react';
import { messaging, db } from './index';
import { toastr } from 'react-redux-toastr';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Toastr = ({ to }) => (
  <Link style={{ color: 'white' }} to={to}>
    View message
  </Link>
);
/**
 * this functions pops a notification permission
 * it writes a new token if token does not exists
 * @param  {String} userUid
 * @returns {FirestoreWrite}
 */
export const askToReceiveNotifications = async (user) => {
  if (!user?.uid || !user?.emailVerified) return;
  const userTokensRef = db.doc(`tokens/${user?.uid}`);
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();
    if (token) {
      const snap = await userTokensRef.get();
      if (!snap.exists || !snap.data().tokens) return userTokensRef.set({ tokens: [token] });
      const existingTokens = snap.data()?.tokens;
      const dupToken = existingTokens.length && existingTokens.find((t) => t === token);
      if (dupToken) return;
      await userTokensRef.update({ tokens: [...existingTokens, token] });
    }
  } catch (err) {
    console.log(err.message);
  }
};
/**
 * functions that listens for new cloud messages
 * diplays a new message as a toaster if app is open
 */
export const getIncomingMessage = async () => {
  try {
    if (messaging) {
      messaging.onMessage(({ notification }) => {
        if (!notification) return;
        const title = notification.title;
        const message = notification.body;
        const toastrOptions = {
          timeOut: 0,
          icon: <Image size='small' avatar src={notification.icon} />,
          component: <Toastr to={notification.click_action} />,
        };
        toastr.info(title, message, toastrOptions);
      });
    }
  } catch (err) {
    console.log(err.message);
  }
};
