import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart, toggleCartDropDown } from '../../redux/actions/cartActions';
import {
  CartDropdownContainer,
  CartItemsContainer,
  EmptyMsgContainer,
} from './cart-dropdown.styles';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Button } from 'semantic-ui-react';
import CartItem from '../cart-item/CartItem';

const CartDropdown = () => {
  const clickRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const cartVisibility = useSelector((state) => state.cart.cartVisibility);
  const cartItem = useSelector((state) => state.cart.cartItem);

  const handleCartDropdownToggle = () => {
    dispatch(toggleCartDropDown());
  };

  useClickOutside(clickRef, handleCartDropdownToggle, cartVisibility);

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleRedirect = (e, data, id) => {
    if (id) {
      history.push(`/rentals/${id}`);
      return;
    }
    handleCartDropdownToggle();
    history.push('/checkout');
  };

  return (
    <CartDropdownContainer ref={clickRef}>
      <CartItemsContainer>
        {!cartItem ? (
          <EmptyMsgContainer>Your cart is empty</EmptyMsgContainer>
        ) : (
          <CartItem
            clearItemFromCart={handleClearCart}
            item={cartItem}
            redirectToRentPage={handleRedirect}
          />
        )}
      </CartItemsContainer>
      <Button
        color='black'
        content='Proceed to checkout'
        onClick={handleRedirect}
      />
    </CartDropdownContainer>
  );
};

export default CartDropdown;
