import { rentalTypes } from '../types';

const initialState = {
  fetchedRental: null,
  favRentals: [],
};

const rentalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case rentalTypes.POPULATE_RENTAL:
      return {
        ...state,
        fetchedRental: action.payload.rentalItem,
      };
    case rentalTypes.CLEAR_RENTAL:
      return {
        ...state,
        fetchedRental: null,
      };
    case rentalTypes.GET_FAV_RENTALS: {
      return {
        ...state,
        favRentals: [...action.payload.rentals],
      };
    }
    case rentalTypes.ADD_FAV_RENTAL:
      if (state.favRentals?.length) {
        const rentalExists = state.favRentals.some((rental) => {
            return rental.id === action.payload.rental.id;
          });

        if (rentalExists) {
          return state;
        }
        return {
          ...state,
          favRentals: [...state.favRentals, action.payload.rental],
        };
      }
      return {
        ...state,
        favRentals: [action.payload.rental],
      };
    case rentalTypes.REMOVE_FAV_RENTAL: {
      const updatedRentals = state.favRentals.filter((rental) => {
        return rental.id !== action.payload.rentalId;
      });
      return {
        ...state,
        favRentals: updatedRentals,
      };
    }
    default:
      return state;
  }
};

export default rentalsReducer;
