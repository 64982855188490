import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from './features/dark-mode/ThemeContext';
import ReduxToastr from 'react-redux-toastr';
import * as serviceWorker from './serviceWorker';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.css';
import ChatWidget from './components/chat-widget/ChatWidget';


const rootEl = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <ReduxToastr
              timeOut={10000}
              preventDuplicates
              position='bottom-right'
              transitionIn='bounceIn'
              transitionOut='bounceOut'
              closeOnToastrClick
            />
            <App />
            <ChatWidget />
          </PersistGate>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>,
    rootEl
  );
};

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      setTimeout(render);
    });
  }
}
if (process.env.NODE_ENV === 'production'){
   console.log = () => null; 
}  

render();

serviceWorker.register();

