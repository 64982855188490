import { asyncActionStart, asyncActionFinish } from './asyncActions';
import { msgActionTypes } from '../types';
import { functions } from '../../firebase';
import { toastr } from 'react-redux-toastr';

export const verifyOwnerIdentity = (file) => async (dispatch, getState) => {
  const currentUser = getState().auth.currentUser;
  if (!currentUser) return;
  if(!file) return toastr.info('Oops', 'Please attach a front page of your document');
  const data = new FormData();
  data.append('file', file);
  try {
    dispatch(asyncActionStart());
    const token = await currentUser.getIdToken();
    const resp = await fetch(
      process.env.REACT_APP_VERIFY_IDENTITY_URL,
      {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!resp.ok) {
      const err = await resp.text()
      dispatch(asyncActionFinish());
      throw new Error(err);
    }
    const resData = await resp.text(); 
    dispatch(asyncActionFinish());
    toastr.success('Success', resData);
  } catch (err) {
    dispatch(asyncActionFinish());
    toastr.error('Oops', err.message);
  }
};


export const retrieveStripeBalance = () => async (dispatch) => {
  const retrieveBalance = functions.httpsCallable('retrieveBalance');
  try {
    dispatch(asyncActionStart());
    const { data } = await retrieveBalance();
    const { amount } = data;
    dispatch({
      type: msgActionTypes.GET_BALANCE,
      payload: { balance: amount },
    });
    dispatch(asyncActionFinish());
  } catch (err) {
    dispatch(asyncActionFinish());
    toastr.error('Oops', 'Did you register your account?');
  }
};

export const createStripeAccount = (data) => async (dispatch) => {
  const createAccount = functions.httpsCallable('createAccount');
  let success = true;
  try {
    dispatch(asyncActionStart());
    await createAccount({ ...data });
    dispatch(asyncActionFinish());
    toastr.success('Success! Your account is created. Now or later, you can upload your identity document.');
    return success;
  } catch (err) {
    dispatch(asyncActionFinish());
    toastr.error('Oops', err.message);
    return (success = null);
  }
};

/**
 * @summary https onCall function that returs sessionId for secure pay
 * @param  {Object} props contains cartItem, fromDate, numOfDates
 * @returns {Object} unique sessionId for stripe checkout
 */
export const createStripeSession = async (props) => {
  const { duration, cartItem, fromDate, price_choice } = props;
  const rentalId = cartItem.id;
  const timestamp = new Date(fromDate).getTime();

  const createSession = functions.httpsCallable('createSession');
  try {
    const { data } = await createSession({ duration, rentalId, timestamp, price_choice });
    const sessionId = data.sessionId;
    return { sessionId };
  } catch (err) {
    throw new Error(err.message);
  }
};

/* export const initiateStripePayout = () => async (dispatch) => {
  const initiatePayout = functions.httpsCallable('initiatePayout');
  try {
    dispatch({ type: msgActionTypes.SET_FUNDS_LOAD });
    const payload = await initiatePayout();
    const { message, code } = payload.data;
    if (code) {
      dispatch({ type: msgActionTypes.END_FUNDS_LOAD });
      throw new Error(code);
    }
    dispatch({ type: msgActionTypes.ADD_MESSAGE, payload: { message } });
  } catch (err) {
    let errMessage = 'Could not transfer funds.'
    if(err.message === 'balance_insufficient'){
        errMessage = 'Your balance is to low to make this transfer.';
    }
    if (err.message === 'aborted') {
      errMessage = 'Can not transfer funds when balance is 0.';
    }
    toastr.error('Oops', errMessage);
  }
}; */
