import styled from 'styled-components';

export const CartDropdownContainer = styled.div`
  position: fixed;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background: ${props => props.theme.background};
  top: 90px;
  right: 40px;
  z-index: 2;
  @media screen and (max-width: 800px) {
    width: 300px;
    height: 300px;
   }
`;

export const EmptyMsgContainer = styled.p`
  font-size: 16px;
  margin: 15px auto;
`;

export const CartItemsContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;


