import { authTypes } from '../types';

const initialState = {
  currentUser: null,
  loadingAuth: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SET_AUTH_LOADING:
      return {
        ...state,
        loadingAuth: true,
      };
    case authTypes.SIGN_IN_SUCCESS:
    case authTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.fetchedUser,
        loadingAuth: false,
      };
    case authTypes.SIGN_OUT:
    case authTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        currentUser: null,
        loadingAuth: false,
      };
    default:
      return state;
  }
};

//import storage from 'redux-persist/lib/storage';
//import { persistReducer } from 'redux-persist';

/* const config = {
  key: 'auth',
  storage,
  blacklist: ['loadingAuth'],
}; */

//persistReducer(config, authReducer);

export default authReducer;
