import React from 'react';
import { ErrorMessageContainer, MessageLabel } from './error-message.styles';

const ErrorMessage = props => {
  const { err } = props;
  return (
    <>
      {err && (
        <ErrorMessageContainer {...props}>
          <MessageLabel basic color='red'> {props.err}</MessageLabel>
        </ErrorMessageContainer>
      )}
    </>
  );
};

export default ErrorMessage;
