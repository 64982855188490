import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openModal } from '../../redux/actions/modalActions';
import { signOut } from '../../redux/actions/authActions';

//components
import Navbar from './Navbar';
import MobileMenu from './MobileMenu';
import CartDropdown from '../cart-dropdown/CartDropdown';
import Drawer from '../drawer/Drawer';

const Header = () => {
    
  const history = useHistory();
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const authUser = useSelector((state) => state.auth.currentUser);
  const showCartDropdown = useSelector((state) => state.cart.cartVisibility);
  const wishlistCount = useSelector((state) => state.rentals.favRentals?.length || 0);

  const handleOpenModal = () => {
    dispatch(openModal('LoginModal'));
    setMobileMenuOpen();
  };

  const handleSignOut = () => {
    dispatch(signOut());
    history.push('/');
    setMobileMenuOpen();
  };

  return (
    <>
      <Navbar
        handleSignOut={handleSignOut}
        handleOpenModal={handleOpenModal}
        handleMenuOpen={setMobileMenuOpen}
        authUser={authUser}
        setDrawerOpen={setDrawerOpen}
        navigation={history}
        wishlistCount={wishlistCount}
      />
      <MobileMenu
        open={mobileMenuOpen}
        handleSignOut={handleSignOut}
        handleOpenModal={handleOpenModal}
        handleMenuOpen={setMobileMenuOpen}
        authUser={authUser}
        wishlistCount={wishlistCount}
      />
      {showCartDropdown && <CartDropdown />}
      <Drawer
        authUser={authUser}
        open={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};

export default Header;
